import { OfferDTO } from '../types';

export const defaultPlanImageUrl = 'https://pixy.fra1.cdn.digitaloceanspaces.com/uploaded-images/2023-07-21/eeb0453e57e8ed0b59dc14c9708d4ea1170a5097dfaa3b4547b84865cb00.jpg';

export const defaultPlan: OfferDTO = {
  id: -1,
  amount: 0,
  price: 0,
  price_eur: 0,
  prices: {
    rub: 0,
    eur: 0,
    usd: 0,
  },
  title: 'Free',
  execution_number: 2,
  execution_period: 86400,
  image_url: defaultPlanImageUrl,
};

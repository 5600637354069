import styled, { css } from 'styled-components';
import Slider from 'react-slick';

export const SliderContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.get(5)};
`;

export const StyledSlider = styled(Slider)`
  ${({ theme }) => css`

  .slick-slide {
      display: flex;
      justify-content: center;
    }

    .slick-slide>div{
      width: 100%;
      max-width: 98%;
    }

    .slick-arrow {
      display: none !important;
    }

    .slick-dots {
      display: block;
      text-align: center;
      bottom: -${theme.spacing.get(5)};
    }

    .slick-dots li {
      width: ${theme.spacing.get(1)};
      margin: ${theme.spacing.get(0, 1)};
    }

    .slick-dots li button {
      width: ${theme.spacing.get(1)};
      padding: 0;
    }

    .slick-dots li button:before {
      left: -${theme.spacing.get(1)};
      opacity: 1;
      color: ${theme.palette.primary10};
      font-size: ${theme.spacing.get(1.5)};
    }


    .slick-dots li.slick-active button:before {
      color: ${theme.palette.primary100};
      opacity: 1;
    }
  `}
`;

import { Expose, Transform, plainToClass } from 'class-transformer';
import { OrderDTO, OrderStatus } from '../types';
import { CurrencyCode } from '../../common';
import { apiParseDate } from '../../api';

export class Order {
  id: number;

  status: OrderStatus;

  @Expose({ name: 'offer_id' })
    offerId: number;

  @Transform(({ value }) => apiParseDate(value))
    created: Date;

  amount: number;

  @Transform(({ value }) => (value ? value.toLowerCase() : null))
    currency: CurrencyCode | null;

  static fromDTO(dto: OrderDTO): Order {
    return plainToClass(Order, dto);
  }
}

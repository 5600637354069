import styled from 'styled-components';

export const StyledTitleWrapper = styled.div`
    max-width: 80%;
`;

export const StyledModalContainer = styled.div`
    width: 100%;
`;

export const StyledInputContainer = styled.div`
    margin: ${({ theme }) => theme.spacing.get(3, 0, 5)};
`;

export const StyledButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

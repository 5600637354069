import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSync = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5.644 5.636A8.972 8.972 0 0 1 12.008 3a8.997 8.997 0 0 1 7.8 4.507V5a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1h-5a1 1 0 1 1 0-2h2.526a7 7 0 0 0-12.776.274c-.178.422-.574.726-1.032.726-.663 0-1.157-.619-.918-1.238a9.009 9.009 0 0 1 2.036-3.126ZM18.39 14.882A7.013 7.013 0 0 1 12.007 19a7 7 0 0 1-6.326-4h2.526a1 1 0 1 0 0-2h-5a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-2.507a8.997 8.997 0 0 0 7.8 4.507 8.972 8.972 0 0 0 6.364-2.636 9.008 9.008 0 0 0 2.036-3.126c.239-.619-.255-1.238-.919-1.238-.457 0-.853.304-1.032.726a6.807 6.807 0 0 1-.068.156Z"
    />
  </svg>
);
export default SvgSync;

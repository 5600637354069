import React, { FC, ReactNode, useMemo } from 'react';
import { OffersContext, OffersContextValue } from './OffersContext';
import { useLoadOffers } from '../../hooks';

export interface OffersContainerProps {
  children: ReactNode;
}

export const OffersContainer: FC<OffersContainerProps> = (props) => {
  const { children } = props;
  const {
    result: offers,
    isLoading,
    error,
    request: refresh,
  } = useLoadOffers();

  const contextValue: OffersContextValue = useMemo(() => {
    return {
      offers,
      isLoading,
      error,
      refresh,
    };
  }, [
    offers,
    isLoading,
    error,
    refresh,
  ]);

  return (
    <OffersContext.Provider value={contextValue}>{children}</OffersContext.Provider>
  );
};

import { enUS, ru } from 'date-fns/locale';

export const UserLocaleMapping = {
  ru,
  en: enUS,
};

export const DateFormatMapping = {
  dayFirstFull: 'dd.MM.yyyy HH:mm',
  monthFirstFull: 'MM.dd.yyyy HH:mm',
  dayFirstShort: 'dd.MM.yy',
  monthFirstShort: 'MM.dd.yy',
  time: 'HH:mm',
};

import styled, { css } from 'styled-components';

export interface StyledModalProps {
  width?: number;
}

export interface StyledOverlayInterface {
  withoutOverlay?: boolean;
  topOffset?: number;
}

export const StyledModalContainer = styled.div<StyledModalProps>`
  position: relative;
  ${({ theme, width }) => css`
    width: ${width ? `${width}px` : 'auto'};
    background: ${theme.palette.background.main};
    padding: ${theme.spacing.get(8, 6)};
    border-radius: ${theme.spacing.get(6)};
  `}
`;

export const StyledOverlay = styled.div<StyledOverlayInterface>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: ${({ withoutOverlay }) => (withoutOverlay ? 'transparent' : 'rgba(36,37,38, 0.6)')};

  ${({ topOffset }) => topOffset && css`
    align-items: flex-start;
    padding-top: ${topOffset}px;
  `}
`;

export const StyledCloseButtonContainer = styled.div`
  position: absolute;
  ${({ theme }) => css`
    top: ${theme.spacing.get(2.5)};
    right: ${theme.spacing.get(2.5)};
    font-size: ${theme.spacing.get(6)};
  `}
`;

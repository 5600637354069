import { apiClient } from '@pixy/core';
import { config } from '../../../config';

export interface SendStyleData {
  query_id: string;
  company: string;
  data: {
    id: string;
    title: string;
    message_text: string;
    photo: string
  };
}

export const sendStyle = async (
  data: SendStyleData,
): Promise<{ statusCode?: number; result: string }> => {
  const response = await apiClient.request({
    path: config.botId ? `/api/v1/telegram_bot/answer/${config.botId}` : '/telegram/v1/answer/',
    method: 'POST',
    body: data,
    headers: {
      'x-company-id': config.company,
    },
  });

  return { statusCode: response.statusCode, result: response.data };
};

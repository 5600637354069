import styled, { css } from 'styled-components';

export const StyledCardContainer = styled.div`
  min-height: 180px;
  position: relative;
  overflow: hidden;
  ${({ theme }) => css`
    padding: ${theme.spacing.get(4, 4, 5)};
    background: linear-gradient(
      270deg,
      ${theme.palette.neutral60} 0%,
      ${theme.palette.neutral80} 66.93%
    );
    border-radius: ${theme.spacing.get(4)};
  `}
`;

export const StyledTitleContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.get(4)};
  `}
`;

export const CurrencyIconHolder = styled.div`
    display: flex;
    align-items: center;
    ${({ theme }) => css`
        transform: translateY(${theme.spacing.get(1)});
        font-size: ${theme.spacing.get(7)};
        color: ${theme.palette.neutral10};
    `}
`;

export const ActionContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.get(4)};
    position: relative;
    min-height: ${theme.spacing.get(8)}
  `}
`;

export const StyledActivePlanMessage = styled.span`
    transform: rotate(-4deg);
    position: absolute;
    ${({ theme }) => css`
        background: ${theme.palette.primary10};
        color: ${theme.palette.primary100};
        padding: ${theme.spacing.get(1, 3)};    
        border-radius: ${theme.spacing.get(8)};
    `}
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ theme }) => theme.spacing.get(40)};
  height: 100%;
  z-index: 0;
`;

import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  Button, Tag, TextInput,
} from '@pixy/ui';
import {
  StyledCreateStylesButtonContainer,
  StyledTagCloudContainer,
} from './styled';
import { createStylePresets } from '../../misc';
import { useTelegram } from '../../../telegram';
import { useSendStyle } from '../../hooks';
import { PageContainer } from '../../../shared';

export const CreateStylesScreen: FC = () => {
  const { queryId } = useTelegram();
  const { request } = useSendStyle();

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const preparedTags = useMemo(
    () => createStylePresets.map(({ id, preset }) => {
      const handleTagClick = () => {
        setInputValue((previousValue) => `${previousValue} ${preset} `);
      };

      return (
        <Tag
          key={`${id}_${preset}`}
          color={isFocused ? 'lightblue' : 'lightgray'}
          onClick={handleTagClick}
        >
          {preset}
        </Tag>
      );
    }),
    [isFocused],
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  const handleReset = useCallback(() => {
    setInputValue('');
  }, []);

  const handleSubmit = useCallback(() => {
    if (queryId) {
      request({ queryId, prompt: inputValue });
    }
  }, [inputValue, queryId, request]);

  return (
    <PageContainer title="Add new style" backUrl="/styles">
      <TextInput
        multiline
        label="Description"
        placeholder="Type something"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChangeText={setInputValue}
        value={inputValue}
      />
      <StyledTagCloudContainer>{preparedTags}</StyledTagCloudContainer>
      <StyledCreateStylesButtonContainer>
        <Button
          fullWidth
          color="base"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          fullWidth
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </StyledCreateStylesButtonContainer>
    </PageContainer>
  );
};

import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import { useTelegram } from '../../telegram';

// eslint-disable-next-line react-hooks/rules-of-hooks
const { locale } = useTelegram();

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(ICU)
  .init({
    fallbackLng: locale,
    supportedLngs: ['ru', 'en'],
    backend: {
      loadPath: `/locales/${locale}/translation.json`,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;

import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  CloseCircleIcon,
  SnackBar,
  AlertProps,
  Stack,
  CopyIcon,
} from '@pixy/ui';
import { useTranslation } from 'react-i18next';
import {
  PageContainer, TitleWrapper, copyText,
} from '../../../shared';
import { AppTabs } from '../../../navigation';
import { TaskSection } from '../../misc';
import { TaskCard } from '../../components';
import { StyledTasksContainer } from './styled';
import { useTelegram } from '../../../telegram';

export const TasksScreeen: FC = () => {
  const { userId } = useTelegram();
  const [alertData, setAlertData] = useState<AlertProps[] | []>([]);

  const { t } = useTranslation();

  const tasksMock: TaskSection[] = useMemo(
    () => [
      {
        title: t('tasks.tasksSections.referrals'),
        tasks: [
          {
            id: '10202',
            text: t('tasks.tasks.friend25'),
            amount: 25,
          },
        ],
      },
    ],
    [t],
  );

  const copyRefferralHandler = useCallback(async () => {
    const copyLink = `https://t.me/piiixybot?start=${userId}`;
    const success = await copyText(copyLink);

    if (success) {
      setAlertData([
        {
          text: t('tasks.alerts.success'),
          variant: 'black',
          closable: true,
          icon: <CopyIcon />,
        },
      ]);
    } else {
      setAlertData([
        {
          text: t('tasks.alerts.error'),
          variant: 'danger',
          closable: true,
          icon: <CloseCircleIcon />,
        },
      ]);
    }
  }, [t, userId]);

  const taskCards = useMemo(
    () => tasksMock.map((item) => (
      <TaskCard key={item.title} {...item} onClick={copyRefferralHandler} />
    )),
    [copyRefferralHandler, tasksMock],
  );

  return (
    <PageContainer>
      <AppTabs />
      <TitleWrapper title={t('tasks.pageHeader')} />
      <StyledTasksContainer>
        <Stack gap={2}>{taskCards}</Stack>
      </StyledTasksContainer>
      <SnackBar alerts={alertData} autoHideDuration={4000} />
    </PageContainer>
  );
};

import { plainToClass } from 'class-transformer';
import { UserDTO } from '../types';

export class User {
  id: string;

  email: string;

  static fromDTO(dto: UserDTO): User {
    return plainToClass(User, dto);
  }
}

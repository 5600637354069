import { apiClient } from '../../api';
import { AuthData } from '../types';

export interface SigninData {
  username: string;
  password: string;
}

export const signin = async (
  data: SigninData,
): Promise<AuthData> => {
  const response = await apiClient.request({
    path: '/api/v1/auth/jwt/login',
    contentType: 'formData',
    method: 'POST',
    body: data,
    withAuth: false,
  });

  return {
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token || null,
  };
};

import {
  FC, useEffect, useState,
} from 'react';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { darkTheme, baseTheme } from '@pixy/ui';
import {
  AuthProvider,
  apiClient,
  useAuthContext,
  useSignin,
  useTWASignin,
} from '@pixy/core';
import { I18nextProvider } from 'react-i18next';
import { GlobalStyles } from './GlobalStyles';
import { useTelegram } from './modules/telegram';
import { config } from './config';
import { StylesRoutes, TasksRoutes, WalletRoutes } from './modules/navigation';
import i18n from './modules/shared/i18n';
import { RotationPlaceHolder } from './modules/shared/components/RotationPlaceholder';
import { SwapScreen } from './modules/swap';
import { isMobileDevice } from './modules/shared';

apiClient.addAuthProvider((options) => {
  if (options.withAuth) {
    // eslint-disable-next-line no-param-reassign
    options.headers = {
      ...options.headers,
      'x-company-id': config.company,
    };
  }
  return options;
});

const Root: FC = () => {
  const { initialized } = useAuthContext();
  const { initData, tg } = useTelegram();
  const { request: signInLogin } = useSignin();
  const { request: TGSignIn } = useTWASignin();

  useEffect(() => {
    if (config.mode === 'development') {
      signInLogin({
        username: config.test_login,
        password: config.test_pass,
      });
      return;
    }

    tg.expand();
    TGSignIn({
      query: initData,
      botId: config.botId,
      companyId: config.company,
    });
  }, [TGSignIn, initData, signInLogin, tg]);

  if (!initialized && config.mode === 'production') {
    return null;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/wallet" />} />
        <Route path="/wallet/*" element={<WalletRoutes />} />
        <Route path="/styles/*" element={<StylesRoutes />} />
        <Route path="/tasks/*" element={<TasksRoutes />} />
        <Route path="/swap" element={<SwapScreen />} />
      </Routes>
    </Router>
  );
};

export const App: FC = () => {
  const [isLandscape, setIsLandscape] = useState(false);
  const { theme } = useTelegram();

  useEffect(() => {
    const handleOrientationChange = () => {
      if (!isMobileDevice()) {
        return;
      }
      if (window?.screen?.orientation?.type) {
        if (window.screen.orientation.type.includes('portrait')) {
          setIsLandscape(false);
        } else if (window.screen.orientation.type.includes('landscape')) {
          setIsLandscape(true);
        }
      } else if (window.matchMedia) {
        const isViewLandscape = window.matchMedia(
          '(orientation: landscape)',
        ).matches;
        setIsLandscape(isViewLandscape);
      }
    };
    handleOrientationChange();

    window.addEventListener('resize', handleOrientationChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <ThemeProvider theme={theme === 'light' ? baseTheme : darkTheme}>
      <GlobalStyles />
      <AuthProvider autoInitialize={false}>
        <I18nextProvider i18n={i18n}>
          <Root />
          {isLandscape && <RotationPlaceHolder />}
        </I18nextProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

import { apiClient } from '../../api';

export interface GeneratePaymentLinkData {
  offer_id: number;
  payment_method: string;
  email: string;
}

export interface GeneratePaymentLinkResponse {
  link: string;
}

export const generatePaymentLink = async (
  data: GeneratePaymentLinkData,
): Promise<GeneratePaymentLinkResponse> => {
  const response = await apiClient.request({
    path: '/api/v1/order/generate-payment-link',
    method: 'POST',
    body: data,
    withAuth: true,
  });

  return response.data;
};

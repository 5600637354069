import { Transform, plainToClass } from 'class-transformer';
import { PaymentMethodDTO, PaymentMethodType } from '../types';
import { CurrencyCode } from '../../common';

export class PaymentMethod {
  id: string;

  name: string;

  type: PaymentMethodType;

  price: number;

  @Transform(({ value }) => (value ? value.toLowerCase() : null))
    currency: CurrencyCode;

  @Transform(({ value }) => {
    if (value) {
      return value;
    }
    return null;
  })
    image: string | null;

  static fromDTO(dto: PaymentMethodDTO): PaymentMethod {
    return plainToClass(PaymentMethod, dto);
  }
}

import React, { FC, useCallback, useMemo } from 'react';
import { Typography, Image, Tag } from '@pixy/ui';
import {
  StyledCardInfoBlock, StyledCardContainer, StyledImageContainer, StyledTagContainer,
} from './styled';
import { Style } from '../../misc/types';
import { useTelegram } from '../../../telegram';
import { useSendStyle } from '../../hooks';

const placeholder = 'https://pixy.fra1.digitaloceanspaces.com/public/twa/styles/default-style.png';

interface StyleCardProps {
  item: Style;
}

export const StyleCard: FC<StyleCardProps> = ({ item }) => {
  const {
    config, image, name,
  } = item;

  const { queryId } = useTelegram();
  const { request } = useSendStyle();

  const style = useMemo(() => {
    try {
      return JSON.parse(config);
    } catch {
      return { prompt: '' };
    }
  }, [config]);

  const onClickHandler = useCallback(() => {
    if (queryId) {
      request({ queryId, prompt: style.prompt });
    }
  }, [style, queryId, request]);

  return (
    <StyledCardContainer onClick={onClickHandler}>
      <StyledImageContainer>
        <Image src={image} placeholder={placeholder} rounded />
      </StyledImageContainer>
      <StyledCardInfoBlock>
        <Typography color="neutral5" variant="textSMBold">{name}</Typography>
        <Typography variant="textXS">{style.prompt}</Typography>
        <StyledTagContainer>
          <Tag color="warning" fontSize={10}>
            Midjourney
          </Tag>
        </StyledTagContainer>
      </StyledCardInfoBlock>
    </StyledCardContainer>
  );
};

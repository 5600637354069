import * as React from 'react';
import type { SVGProps } from 'react';

const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M10.75 6.75a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM17 10.5A3.75 3.75 0 1 0 17 3a3.75 3.75 0 0 0 0 7.5ZM15.465 20c.34-.588.535-1.271.535-2v-1.833c0-.836-.26-1.757-.918-2.505-.35-.399-.729-.772-1.134-1.115A8.732 8.732 0 0 1 17 12a8.73 8.73 0 0 1 6.58 2.981c.285.326.42.752.42 1.186V18a2 2 0 0 1-2 2h-6.535ZM13.96 18.403A2 2 0 0 1 12 20H2a2 2 0 0 1-2-2v-1.833c0-.434.135-.86.42-1.186A8.73 8.73 0 0 1 7 12c1.86 0 3.583.58 5 1.568.582.406 1.112.881 1.58 1.413.285.326.42.752.42 1.186V18c0 .138-.014.273-.04.403Z"
    />
  </svg>
);
export default SvgUsers;

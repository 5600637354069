import { Currency, CurrencyCode } from '../types';

export const currencies: Array<Currency> = [
  {
    code: CurrencyCode.RUB,
    decimalPlaces: 0,
    displayDecimals: 2,
    symbol: '₽',
    symbolPlacement: 'end',
  },
  {
    code: CurrencyCode.EUR,
    decimalPlaces: 0,
    displayDecimals: 2,
    symbol: '€',
    symbolPlacement: 'start',
  },
  {
    code: CurrencyCode.USD,
    decimalPlaces: 0,
    displayDecimals: 2,
    symbol: '$',
    symbolPlacement: 'start',
  },
  {
    code: CurrencyCode.USDT,
    decimalPlaces: 0,
    displayDecimals: 2,
    symbol: 'USDT',
    symbolPlacement: 'end',
  },
  {
    code: CurrencyCode.BTC,
    decimalPlaces: 0,
    displayDecimals: 8,
    symbol: 'BTC',
    symbolPlacement: 'end',
  },
];

type CurrencyDict = Record<CurrencyCode, Currency>;

export const currenciesDict: CurrencyDict = currencies.reduce(
  (previousObject, currentObject) => {
    // eslint-disable-next-line no-param-reassign
    previousObject[currentObject.code] = currentObject;
    return previousObject;
  },
  {} as CurrencyDict,
);

import * as React from 'react';
import type { SVGProps } from 'react';

const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-10.724 1.507c-.278 0-.513-.228-.477-.502.02-.152.052-.26.096-.407l.026-.092c.1-.332.243-.6.432-.806.188-.204.415-.39.68-.556.199-.127.376-.26.533-.398a1.72 1.72 0 0 0 .374-.455c.092-.17.139-.358.139-.566 0-.22-.053-.413-.159-.58a1.053 1.053 0 0 0-.426-.383 1.27 1.27 0 0 0-.585-.134 1.3 1.3 0 0 0-.59.139 1.126 1.126 0 0 0-.554.589c-.1.249-.306.47-.575.47h-.961c-.281 0-.512-.233-.467-.51.067-.415.205-.773.414-1.072.291-.419.676-.73 1.156-.935a3.949 3.949 0 0 1 1.586-.311c.637 0 1.199.105 1.688.316.489.208.873.51 1.15.906.279.396.418.874.418 1.433 0 .374-.063.707-.187.998a2.429 2.429 0 0 1-.513.767c-.22.22-.481.42-.782.599-.252.15-.46.307-.623.47a1.596 1.596 0 0 0-.376.614c-.076.23-.28.405-.521.405h-.896Zm.446 3.568c-.32 0-.593-.112-.82-.336a1.103 1.103 0 0 1-.33-.815 1.081 1.081 0 0 1 .33-.805c.227-.224.5-.336.82-.336.304 0 .57.112.8.336a1.1 1.1 0 0 1 .183 1.385 1.239 1.239 0 0 1-.417.417 1.089 1.089 0 0 1-.566.154Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQuestion;

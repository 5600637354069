import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  CurrencyContainer,
  OffersContainer,
  WalletContainer,
} from '@pixy/core';
import {
  ManagePlansScreen,
  PaymentHistoryScreen,
  PaymentScreen,
  WalletScreen,
} from '../../wallet';

export const WalletRoutes: React.FC = () => {
  return (
    <CurrencyContainer>
      <OffersContainer>
        <WalletContainer>
          <Routes>
            <Route index element={<WalletScreen />} />
            <Route path="/buy-generations" element={<ManagePlansScreen />} />
            <Route
              path="/payments-history"
              element={<PaymentHistoryScreen />}
            />
            <Route path="/make-payment/:id" element={<PaymentScreen />} />
          </Routes>
        </WalletContainer>
      </OffersContainer>
    </CurrencyContainer>
  );
};

import { useMemo } from 'react';
import { Currency, CurrencyCode } from '../types';
import { currencies } from '../data';

export const useCurrency = (code: CurrencyCode, fallback?: CurrencyCode): Currency | null => {
  const currency = useMemo(() => {
    let result = currencies.find((x) => x.code === code);
    if (!result && fallback && code !== fallback) {
      result = currencies.find((x) => x.code === fallback);
    }
    return result || null;
  }, [code, fallback]);

  return currency;
};

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CaretRightIcon, Select, Skeleton, Stack, Typography,
} from '@pixy/ui';
import { CurrencyIcon, PackagesBoard } from '@pixy/web-base';
import {
  CurrencyCode,
  useCurrencyContext,
  useOffersContext,
  useWalletContext,
} from '@pixy/core';
import { PageContainer } from '../../../shared';
import { PlansBoard } from '../../components';
import {
  BoardContainer, IconHolder, OptionContainer, TaskLinkContainer,
} from './styled';
import { config } from '../../../../config';

const getOption = (value: CurrencyCode, text: string) => {
  return (
    <OptionContainer>
      <CurrencyIcon code={value} />
      <Typography color="neutral5" variant="textLG">{text}</Typography>
    </OptionContainer>
  );
};

const options = [
  {
    value: CurrencyCode.RUB.toUpperCase(),
    label: getOption(CurrencyCode.RUB, 'Рубли'),
  },
  {
    value: CurrencyCode.EUR.toUpperCase(),
    label: getOption(CurrencyCode.EUR, 'Euro'),
  },
];

export const ManagePlansScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { balance } = useWalletContext();
  const { offers, isLoading: offersIsLoading } = useOffersContext();
  const { currency, setCurrency } = useCurrencyContext();

  const currentPlanId = balance?.currentOffer?.id ?? null;

  const setCurrencyHandler = useCallback(
    (value: string) => {
      setCurrency(value.toLowerCase() as CurrencyCode);
    },
    [setCurrency],
  );

  const getPaymentsLink = useCallback(
    (id: number) => {
      navigate(`/wallet/make-payment/${id}`);
    },
    [navigate],
  );

  const openTasksPage = useCallback(() => {
    navigate('/tasks');
  }, [navigate]);

  const isTasksAllowed = !config.components || config?.components?.includes('task');

  const currencySelector = useMemo(() => {
    return (
      <Select
        defaultValue={CurrencyCode.EUR.toUpperCase()}
        onValueChange={setCurrencyHandler}
        options={options}
      />
    );
  }, [setCurrencyHandler]);

  return (
    <PageContainer
      title={t('wallet.manage.pageHeader')}
      backUrl="/wallet"
      toolbar={currencySelector}
      noPadding
    >
      <Stack gap={5}>
        <PlansBoard
          plans={offers}
          currency={currency.code}
          currentPlanId={currentPlanId}
          onClick={getPaymentsLink}
        />
        {offersIsLoading || !offers?.length ? (
          <Skeleton height="200px" />
        ) : (
          <BoardContainer>
            <PackagesBoard onClick={getPaymentsLink} />
          </BoardContainer>
        )}
        {isTasksAllowed && (
        <BoardContainer>
          <TaskLinkContainer onClick={() => openTasksPage()}>
            <Typography variant="textSMBold" color="neutral5">
              {t('tasks.linkButton')}
            </Typography>
            <IconHolder>
              <CaretRightIcon />
            </IconHolder>
          </TaskLinkContainer>
        </BoardContainer>
        )}
      </Stack>
    </PageContainer>
  );
};

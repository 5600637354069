import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Stack } from '@pixy/ui';
import { useLoadOrders } from '@pixy/core';
import { OrderCard } from '@pixy/web-base';
import { PageContainer } from '../../../shared';
import { EmptyBlockText } from './styled';

const placeHolderArray = new Array(15).fill(0).map((_, i) => { return i; });

export const PaymentHistoryScreen = () => {
  const { t } = useTranslation();
  const { result: orders, isLoading: ordersIsLoading } = useLoadOrders();

  const cards = useMemo(() => {
    return orders?.data?.map((payment) => {
      return <OrderCard key={payment.id} item={payment} />;
    });
  }, [orders]);

  const renderOrders = useMemo(() => {
    if (ordersIsLoading) {
      return placeHolderArray.map((num) => {
        return <Skeleton height="80px" key={num} />;
      });
    }

    if (!cards?.length && !ordersIsLoading) {
      return (
        <EmptyBlockText
          color="neutral10"
          variant="textXS"
        >
          {t('wallet.history.terms.noData')}
        </EmptyBlockText>
      );
    }

    return cards;
  }, [cards, ordersIsLoading, t]);

  return (
    <PageContainer title={t('wallet.history.pageHeader')} backUrl="/wallet">
      <Stack gap={ordersIsLoading ? 4 : 0}>
        {renderOrders}
      </Stack>
    </PageContainer>
  );
};

import { apiClient } from '../../api';
import { AuthData } from '../types';

export interface RefreshTokenData {
  refreshToken: string;
}

export const refreshToken = async (
  data: RefreshTokenData,
): Promise<AuthData> => {
  const response = await apiClient.request({
    path: '/api/token/refresh/',
    method: 'POST',
    body: data,
    withAuth: false,
  });

  return response.data;
};

import React, { FC } from 'react';
import { Stack, useBreakpoint } from '@pixy/ui';
import { useTranslation } from 'react-i18next';
import { Order as Payment } from '@pixy/core';
import { NoDataPlaceHolder } from './styled';
import { OrderCard } from './elements';

interface OrdersBoardProps {
  orders: Payment[] | null;
}

export const OrdersBoard: FC<OrdersBoardProps> = ({
  orders,
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint('down', 'md');

  const cards = orders?.map((payment) => <OrderCard key={payment.id} item={payment} />);

  const emptyBlock = (
    <NoDataPlaceHolder
      color="neutral10"
      variant={isMobile ? 'textXS' : 'textSM'}
    >
      {t('wallet.history.terms.noData')}
    </NoDataPlaceHolder>
  );

  return (
    <Stack gap={1}>{cards && cards.length ? cards : emptyBlock}</Stack>
  );
};

import jwtDecode from 'jwt-decode';
import { Listener, ListenerEventMap } from '../../common';
import { AuthStorage } from '../storage';
import { AuthData, JwtTokenData } from '../types';
import { refreshToken } from '../network';

interface AuthServiceEventMap extends ListenerEventMap {
  change: {
    initialized: boolean;
    authorized: boolean;
  };
  beforeLogout: unknown;
}

class AuthService extends Listener<AuthServiceEventMap> {
  initialized = false;

  authorized = false;

  authStorage: AuthStorage | null;

  private refreshTokenPromise: Promise<void> | null = null;

  initialize = async (): Promise<void> => {
    const data = await this.getAuthData();
    if (data) {
      try {
        await this.checkAccessToken();
        this.authorized = true;
      } catch (e) {
        this.authorized = false;
        this.logout();
      }
    } else {
      this.authorized = false;
    }
    this.initialized = true;
    this.triggerChange();
  };

  private triggerChange = () => {
    this.trigger('change', {
      initialized: this.initialized,
      authorized: this.authorized,
    });
  };

  private refreshAccessTokenHandler = async (): Promise<void> => {
    const authData = await this.getAuthData();
    if (!authData?.refreshToken) {
      this.refreshTokenPromise = null;
      throw new Error("Don't have a refresh token");
    }
    const result = await refreshToken({
      refreshToken: authData?.refreshToken,
    });
    if (result.accessToken) {
      await this.setAuthData({
        ...authData,
        refreshToken: result.refreshToken || authData.refreshToken,
        accessToken: result.accessToken,
      });
      this.refreshTokenPromise = null;
      return;
    }
    this.refreshTokenPromise = null;
    throw new Error('Failed to refresh token');
  };

  private refreshAccessToken = async (): Promise<void> => {
    if (this.refreshTokenPromise) {
      return this.refreshTokenPromise;
    }
    this.refreshTokenPromise = this.refreshAccessTokenHandler();
    return this.refreshTokenPromise;
  };

  private checkAccessToken = async (): Promise<void> => {
    const authData = await this.getAuthData();
    if (!authData) {
      this.logout();
      throw new Error('No Auth Data');
    }
    const { exp } = jwtDecode<JwtTokenData>(authData.accessToken);
    const nowSeconds = Date.now() / 1000;

    if (exp - nowSeconds > 30) {
      // Has enough life, do nothing
      return;
    }

    if (authData.refreshToken) {
      const { exp: refreshExp } = jwtDecode<JwtTokenData>(
        authData.refreshToken,
      );
      if (refreshExp - nowSeconds > 1800) {
        // We can refresh
        await this.refreshAccessToken();
        return;
      }
    }

    this.logout();
    throw new Error('Your session has expired');
  };

  getAuthData = async (): Promise<AuthData | null> => {
    if (!this.authStorage) {
      throw new Error('authStorage is not provided');
    }
    return this.authStorage?.getAuthData() || null;
  };

  setAuthData = async (authData: AuthData): Promise<void> => {
    if (!this.authStorage) {
      throw new Error('authStorage is not provided');
    }
    if (!authData) {
      throw new Error('Token Data not provided');
    }
    await this.authStorage.setAuthData(authData);
    this.authorized = true;
    this.initialized = true;
    this.triggerChange();
  };

  logout = async (): Promise<void> => {
    await this.triggerAwait('beforeLogout', null);
    this.authStorage?.clear();
    this.authorized = false;
    this.triggerChange();
  };

  getValidAccessToken = async (): Promise<string> => {
    await this.checkAccessToken();
    const authData = await this.getAuthData();
    if (authData) {
      return authData.accessToken;
    }
    throw new Error("Don't have valid access token");
  };
}

export const authService = new AuthService();

import { FC, useMemo } from 'react';
import { Accordion, Tag, Typography } from '@pixy/ui';
import { TaskData, TaskSection } from '../../misc';
import { StyledTaskContainer, StyledTaskSection, TagHolder } from './styled';

export const TaskCard: FC<TaskSection> = ({ title, tasks, onClick }) => {
  const tasksSections = useMemo(() => {
    return tasks.map((task:TaskData) => (
      <StyledTaskSection key={task.id} onClick={onClick}>
        <Typography>{task.text}</Typography>
        <TagHolder>
          <Tag view="label" color="white">{`+ ${task.amount}`}</Tag>
        </TagHolder>
      </StyledTaskSection>
    ));
  }, [tasks, onClick]);

  return (
    <StyledTaskContainer>
      <Accordion title={title} opened>{tasksSections}</Accordion>
    </StyledTaskContainer>
  );
};

import styled, { css } from 'styled-components';

export interface StyledContainerProps {
  noPadding?: boolean;
  title?: string;
}

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.get(1.5)};
`;

export const StyledHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => css`
      background: ${theme.palette.background.main};
  `}
  margin-bottom: ${({ theme }) => theme.spacing.get(4)};


  position: sticky;
  top: 0;
  z-index: 9999;
`;

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => css`
    background: ${theme.palette.background.main};
    padding: ${theme.spacing.get(0, 4, 10)};
  `}

  ${({ title, theme }) => title && css`
    ${StyledHeaderContainer}{
      padding: ${theme.spacing.get(7, 0, 0)};
    }
  `}

  ${({ noPadding, theme }) => noPadding && css`
    padding: ${theme.spacing.get(0, 0, 10)};
    ${StyledHeaderContainer}{
      padding: ${theme.spacing.get(7, 4, 0)};
    }
  `}
`;

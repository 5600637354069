import { useCallback, useMemo, useState } from 'react';
import {
  ReqestFiltersParams,
  RequestListParams,
  RequestSortParams,
} from '../types';
import { useDebounce } from './useDebounce';

export const useListState = <Filters = ReqestFiltersParams>(
  defaultParams: RequestListParams<Filters> = {},
) => {
  const {
    paging: defaultPaging = null,
    sort: defaultSort = null,
    filters: defaultFilters = null,
  } = defaultParams;

  const [page, setPage] = useState<number>(defaultPaging?.page ?? 1);
  const [pageSize, setPageSize] = useState<number>(
    defaultPaging?.pageSize ?? 20,
  );
  const [sort, setSort] = useState<RequestSortParams | null>(defaultSort);
  const [filters, setFiltersState] = useState<Filters | null>(defaultFilters);

  const setFilters = useCallback((data: Filters | null) => {
    setPage(1);
    setFiltersState(data);
  }, []);

  const state = useMemo(() => {
    const result: RequestListParams<Filters> = {
      paging: {
        page,
        pageSize,
      },
      sort,
      filters,
    };
    return result;
  }, [page, pageSize, sort, filters]);

  const debounceState = useDebounce(state);

  return {
    page,
    pageSize,
    sort,
    filters,
    debounceState,
    setPage,
    setPageSize,
    setSort,
    setFilters,
  };
};

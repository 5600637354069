import styled, { css } from 'styled-components';

export const StyledNavigationWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  ${({ theme }) => css`
    background: ${theme.palette.background.main};
  `}
`;

export const StyledWalletScreenContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  background: ${({ theme }) => theme.palette.background.main};
`;

export const StyledOrdersLoadingContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBoardContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.get(4)}
`;

export const OrdersContainer = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.get(8)};
  `}
`;

import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Stack } from '@pixy/ui';
import { useGeneratePaymentLink, useLoadPaymentMethods } from '@pixy/core';
import { useParams } from 'react-router-dom';
import { PaymentCard } from '@pixy/web-base';
import { PageContainer } from '../../../shared';
import { useTelegram } from '../../../telegram';

const placeHolderArray = new Array(5).fill(0).map((_, i) => { return i; });

export const PaymentScreen: FC = () => {
  const { tg } = useTelegram();
  const { t } = useTranslation();
  const { id: offerId = '' } = useParams();

  const { result: paymentMethods, isLoading } = useLoadPaymentMethods({
    offerId,
  });

  const {
    isLoading: isLinkGenerating,
    request: generateLink,
    result: isLinkGenerated,
  } = useGeneratePaymentLink();

  const PaymentClickHandler = useCallback(
    ({ id, email = '' }: { id: string; email?: string }) => {
      generateLink({
        offer_id: Number(offerId),
        payment_method: id,
        email,
      });
    },
    [generateLink, offerId],
  );

  useEffect(() => {
    if (isLinkGenerated) {
      tg.openLink(isLinkGenerated.link);
    }
  }, [isLinkGenerated, tg]);

  return (
    <PageContainer
      title={t('wallet.payments.pageHeader')}
      backUrl="/wallet/buy-generations"
    >
      <Stack gap={3}>
        {isLoading || !paymentMethods?.length
          ? placeHolderArray.map((num) => (
            <Skeleton key={num} height="120px" />
          ))
          : paymentMethods?.map((item) => {
            return (
              <PaymentCard
                key={item.id}
                item={item}
                onClick={PaymentClickHandler}
                isDisabled={isLinkGenerating}
              />
            );
          })}
      </Stack>
    </PageContainer>
  );
};

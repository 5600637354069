import { Expose, Type, plainToClass } from 'class-transformer';
import { BalanceDTO } from '../types';
import { Offer } from './Offer';

export class Balance {
  @Expose({ name: 'current_offer' })
  @Type(() => Offer)
    currentOffer: Offer | null;

  @Expose({ name: 'available_tasks' })
    availableTasks: BalanceDTO['available_tasks'];

  static fromDTO(dto: BalanceDTO): Balance {
    return plainToClass(Balance, dto);
  }
}

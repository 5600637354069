import styled, { css } from 'styled-components';

export const StyledDescriptionWrapper = styled.div`
    position: sticky;
    top: 0;
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing.get(2)};
        padding: ${theme.spacing.get(8, 5, 1)};
        margin-bottom: ${theme.spacing.get(4)};
        background: ${theme.palette.background.main};
    `}
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 2px;
`;

export const LargeItem = styled.div`
  grid-column: span 2;
  grid-row: span 2;
`;

export const LargeItemInverted = styled.div`
   grid-column: 3 / span 2;
   grid-row: span 2;
`;

export const SmallItem = styled.div`
  grid-column: span 1;
  grid-row: span 1;
`;

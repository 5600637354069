/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { RequestState, useRequestState } from './useRequestState';

export interface UseRequestResult<Result> extends RequestState<Result> {}

export const useRequest = <Fn extends (...args: any[]) => any>(
  request: Fn,
): RequestState<Awaited<ReturnType<Fn>>> & {
  request: (...args: Parameters<Fn>) => Promise<void>
} => {
  const {
    setResult,
    setIsLoading,
    setError,
    ...state
  } = useRequestState<Awaited<ReturnType<Fn>>>();

  const wrappedRequest = useCallback(async (...args: Parameters<Fn>) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await request(...args);
      setResult(response);
    } catch (e) {
      setResult(null);
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }
    setIsLoading(false);
  }, [request]);

  return {
    request: wrappedRequest,
    ...state,
  };
};

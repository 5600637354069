import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PresetList } from '../../components';
import { StyledNavigationWrapper, StylesTitleWrapper } from './styled';
import { AppTabs } from '../../../navigation';
import { PageContainer, TitleWrapper } from '../../../shared';

export const StylesScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer noPadding>
      <StyledNavigationWrapper>
        <AppTabs />
      </StyledNavigationWrapper>
      <StylesTitleWrapper>
        <TitleWrapper
          withOffset
          title={t('styles.pageHeader')}
          // href={TWARoutes.stylesCreate}
          // buttonText="Add your style"
        />
      </StylesTitleWrapper>
      <PresetList />
    </PageContainer>
  );
};

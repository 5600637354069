import { useCallback, useState } from 'react';
import { sendStyle, SendStyleData } from '../network/botSending';
import { config } from '../../../config';

export type StyleData = {
    queryId: string,
    prompt?: string,
    imageUrl?: string
}

export const useSendStyle = (): {
  result: boolean | null,
  isLoading: boolean,
  error: Error | null,
  request: (data: StyleData) => void,
} => {
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async ({ queryId, prompt = '', imageUrl = '' }:StyleData) => {
      setIsLoading(true);
      setError(null);
      try {
        const data:SendStyleData = {
          query_id: queryId,
          company: config.company,
          data: {
            id: '10',
            title: 'Response',
            message_text: prompt,
            photo: imageUrl,
          },
        };
        await sendStyle(data);
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }

      setIsLoading(false);
    },
    [],
  );

  return {
    result,
    isLoading,
    error,
    request,
  };
};

import React, { FC, ReactNode } from 'react';
import { StyledTabLink } from './styled';
import { ThemeTypographyKey } from '../../../../themes';

type LinkProps = {
    path: string;
    variant?: ThemeTypographyKey;
    children?: ReactNode
}

export const TabLink: FC<LinkProps> = ({ children, path, variant }) => {
  return (
    <StyledTabLink variant={variant} to={path}>
      {children}
    </StyledTabLink>
  );
};

import React, { FC } from 'react';
import { Image, Stack, Typography } from '@pixy/ui';
import { useTranslation } from 'react-i18next';
import { ImageContainer, StyledPlaceholderContainer } from './styled';
import pixyImage from './static/pixy.jpg';

export const RotationPlaceHolder: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledPlaceholderContainer>
      <Stack
        direction="row"
        gap={4}
        alignItems="center"
        justifyContent="center"
      >
        <ImageContainer>
          <Image src={pixyImage} />
        </ImageContainer>
        <Stack gap={1}>
          <Typography variant="textXSBold" color="primary100">
            PIXY
          </Typography>
          <Typography>{t('rotateDevice')}</Typography>
        </Stack>
      </Stack>
    </StyledPlaceholderContainer>
  );
};

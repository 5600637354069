export const copyText = async (text: string): Promise<boolean> => {
  let success = false;
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      success = true;
    } catch (e) {
      console.error('Error trying to copy text with navigator.clipboard:', e);
    }
  }

  if (!success) {
    const input = document.createElement('input');
    input.value = text;
    input.style.position = 'absolute';
    input.style.left = '-9999px';
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length);

    try {
      document.execCommand('copy');
      success = true;
    } catch (err) {
      console.error('Error trying to copy text with document.execCommand:', err);
    } finally {
      document.body.removeChild(input);
    }
  }

  return success;
};

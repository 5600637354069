import { Expose, plainToClass } from 'class-transformer';
import { OfferDTO } from '../types';
import { defaultPlan } from '../data';
import { CurrencyPriceMap } from '../../common';

export class Offer {
  id: number;

  amount: number;

  title: string;

  @Expose({ name: 'execution_number' })
    executionNumber: number;

  @Expose({ name: 'execution_period' })
    executionPeriod: number;

  @Expose({ name: 'prices' })
    prices: CurrencyPriceMap;

  @Expose({ name: 'image_url' })
    imageUrl: string;

  get isPackage(): boolean {
    return this.amount > 0;
  }

  get isPlan(): boolean {
    return this.executionNumber > 0 || this.executionPeriod > 0;
  }

  get isDefaultPlan(): boolean {
    return this.isPlan && this.id === defaultPlan.id;
  }

  static fromDTO(dto: OfferDTO): Offer {
    return plainToClass(Offer, dto);
  }
}

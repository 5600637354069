import React, { MouseEventHandler, ReactNode } from 'react';
import { FCWithAs } from '../../types';
import { IconButtonBaseProps } from './types';
import { Loader } from '../Loader';
import {
  ContainedButton, Content, Label, LoaderWrapper, Shape,
} from './styled';

export interface IconButtonProps extends IconButtonBaseProps {
  children?: ReactNode;
  label?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const IconButton: FCWithAs<'button', IconButtonProps> = ({
  as,
  size = 'sm',
  color = 'primary',
  disabled = false,
  loading = false,
  label,
  labelPosition = 'right',
  children,
  onClick,
  ...rest
}) => {
  return (
    <ContainedButton
      as={as as never}
      size={size}
      color={color}
      disabled={disabled}
      loading={loading}
      labelPosition={labelPosition}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      <Shape>
        <Content>
          {children}
        </Content>
        {loading && (
          <LoaderWrapper>
            <Loader color="inherit" />
          </LoaderWrapper>
        )}
      </Shape>
      {label && <Label>{label}</Label>}
    </ContainedButton>
  );
};

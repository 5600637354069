import { apiClient } from '../../api';
import { PaymentMethod } from '../models';

export interface LoadPaymentMethodsData {
  offerId: number | string;
}

export const loadPaymentMethods = async (
  data: LoadPaymentMethodsData,
): Promise<Array<PaymentMethod>> => {
  const { offerId } = data;
  const response = await apiClient.request({
    path: `/api/v1/offer/${offerId}/payment/method`,
    method: 'GET',
    withAuth: true,
  });

  return response.data.map((x) => PaymentMethod.fromDTO(x));
};

import { getQueryConfig } from './getQueryConfig';
import { Config } from './types';

const params = getQueryConfig();
export const config: Config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL ?? 'https://api-p.piiixy.com',
  },
  botId: params.get('bot_id') || null,
  company: params.get('company_id') || process.env.REACT_APP_COMPANY || 'pixy',
  components: null,
  mode: process.env.REACT_APP_MODE ?? 'production',
  test_login: process.env.REACT_APP_TEST_LOGIN ?? '',
  test_pass: process.env.REACT_APP_TEST_PASS ?? '',
};

if (params.get('components')) {
  config.components = params.get('components')?.split(',') as Config['components'] || null;
}

import {
  Expose, ExposeOptions, Transform, TransformationType,
} from 'class-transformer';
import get from 'lodash/get';

// TODO: add support for toPlain

export const ExposePath = (options?: ExposeOptions) => {
  const name = options?.name || null;
  if (!name || !name.includes('.')) {
    return Expose(options);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (target: any, key: string) => {
    Expose({
      ...options,
      toClassOnly: true,
    })(target, key);
    Transform(({
      obj, type, value,
    }) => {
      if (type === TransformationType.PLAIN_TO_CLASS) {
        return get(obj, options?.name || key);
      }
      return value;
    })(target, key);
  };
};

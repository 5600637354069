import * as React from 'react';
import type { SVGProps } from 'react';

const SvgMoodHappy = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-11.5-2a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm4.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-6.742 3c.459-.307 1.073-.17 1.497.185.608.51 1.39.815 2.245.815.854 0 1.637-.306 2.245-.815.424-.354 1.038-.492 1.497-.185.46.307.588.936.203 1.332A5.485 5.485 0 0 1 12 17.5a5.485 5.485 0 0 1-3.945-1.668c-.385-.396-.256-1.025.203-1.332Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMoodHappy;

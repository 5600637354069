import { createContext } from 'react';
import { RequestError } from '../../../httpClient';
import { User } from '../../models';

export interface UserContextValue {
  currentUser: User | null;
  isLoading: boolean;
  error: Error | RequestError | null;
  refresh: () => void;
}

export const UserContext = createContext<UserContextValue>(
  {} as UserContextValue,
);

import React, { MouseEventHandler, ReactNode } from 'react';
import {
  ContainedButton, Content, IconHolder, LoaderWrapper, TextButton,
} from './styled';
import { FCWithAs } from '../../types';
import { ButtonBaseProps, ButtonVariant } from './types';
import { Loader } from '../Loader';

export interface ButtonProps extends ButtonBaseProps {
  children?: ReactNode;
  variant?: ButtonVariant;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Button: FCWithAs<'button', ButtonProps> = ({
  as,
  variant = 'contained',
  size = 'sm',
  color = 'primary',
  disabled = false,
  loading = false,
  fullWidth = false,
  startIcon = null,
  endIcon = null,
  children,
  onClick,
  ...rest
}) => {
  const Component = variant === 'contained' ? ContainedButton : TextButton;
  return (
    <Component
      as={as as never}
      size={size}
      color={color}
      disabled={disabled}
      loading={loading}
      fullWidth={fullWidth}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      <Content>
        {startIcon && <IconHolder>{startIcon}</IconHolder>}
        {children}
        {endIcon && <IconHolder>{endIcon}</IconHolder>}
      </Content>
      {loading && (
        <LoaderWrapper>
          <Loader color="inherit" />
        </LoaderWrapper>
      )}
    </Component>
  );
};

export enum CurrencyCode {
  RUB = 'rub',
  EUR = 'eur',
  USD = 'usd',
  USDT = 'usdt',
  BTC = 'btc',
}

export interface Currency {
  code: CurrencyCode;
  /** Decimals places for stored values */
  decimalPlaces: number;
  displayDecimals: number;
  symbol: string;
  symbolPlacement: 'start' | 'end';
}

export type CurrencyPriceMap = Record<CurrencyCode, number>

import React, { FC, useMemo } from 'react';
import { CurrencyCode, Offer, usePrice } from '@pixy/core';
import {
  Button, Image, Stack, Typography,
} from '@pixy/ui';
import { useTranslation } from 'react-i18next';
import {
  ActionContainer,
  CurrencyIconHolder,
  ImageContainer,
  StyledActivePlanMessage,
  StyledCardContainer,
  StyledTitleContainer,
} from './styled';
import { CurrencyIcon, useTimeFormatter } from '../../shared';

interface PlanOfferCardProps {
  item: Offer;
  currency: CurrencyCode;
  isActive?: boolean;
  onClick?: () => void;
}

export const SubscribePlanCard: FC<PlanOfferCardProps> = ({
  item,
  currency = CurrencyCode.RUB,
  isActive,
  onClick,
}) => {
  const { t } = useTranslation();
  const currencyCode = currency;
  const { formatTime } = useTimeFormatter();

  const price = usePrice(item.prices[currencyCode], currencyCode);

  const formattedPrice = currencyCode === CurrencyCode.EUR || currencyCode === CurrencyCode.RUB
    ? price.value
    : price.formattedValue;

  const isFree = price.value === 0;

  const Action = useMemo(() => {
    if (!isFree && !isActive) {
      return (
        <Button onClick={onClick} size="xs" color="light">
          {t('wallet.manage.terms.buy')}
        </Button>
      );
    }
    if (isActive) {
      return (
        <StyledActivePlanMessage>
          <Typography variant="textSMBold">
            {t('wallet.manage.terms.activePlan')}
          </Typography>
        </StyledActivePlanMessage>
      );
    }

    return null;
  }, [isFree, isActive]);

  return (
    <StyledCardContainer>
      <ImageContainer>
        <Image src={item.imageUrl} />
      </ImageContainer>
      <StyledTitleContainer>
        <Typography variant="textSMBold" color="neutral5">
          {item.title}
        </Typography>
      </StyledTitleContainer>
      <Stack gap={1}>
        {isFree ? (
          <Typography variant="headerSM">
            {t('wallet.manage.base.price')}
          </Typography>
        ) : (
          <Stack direction="row" alignItems="baseline">
            <Typography variant="headerMD" color="neutral5">
              {formattedPrice}
            </Typography>
            <CurrencyIconHolder>
              <CurrencyIcon code={currencyCode} />
            </CurrencyIconHolder>
            <Typography color="neutral10">
              {`/ ${t('wallet.manage.terms.month')}`}
            </Typography>
          </Stack>
        )}
        <Typography variant="textXS" color="neutral10">
          {`${t('pluralize.generation', { count: item.executionNumber })} ${t(
            'wallet.manage.terms.per',
          )} ${formatTime(item.executionPeriod)}`}
        </Typography>
      </Stack>
      {Action && <ActionContainer>{Action}</ActionContainer>}
    </StyledCardContainer>
  );
};

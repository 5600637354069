import React, {
  FC, ReactNode, useMemo, useState,
} from 'react';
import { CurrencyContext, CurrencyContextValue } from './CurrencyContext';
import { CurrencyCode } from '../../types';
import { currencies } from '../../data';
import { useCurrency } from '../../hooks';

export interface CurrencyProviderProps {
  defaultCurrency?: CurrencyCode;
  children: ReactNode;
}

export const CurrencyContainer: FC<CurrencyProviderProps> = (props) => {
  const { defaultCurrency = CurrencyCode.EUR, children } = props;
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(defaultCurrency);

  const currency = useCurrency(currencyCode, defaultCurrency) || currencies[0];

  const contextValue: CurrencyContextValue = useMemo(() => {
    return {
      currency,
      setCurrency: setCurrencyCode,
    };
  }, [
    currency,
    setCurrencyCode,
  ]);

  return (
    <CurrencyContext.Provider value={contextValue}>{children}</CurrencyContext.Provider>
  );
};

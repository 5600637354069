import styled, { css } from 'styled-components';

export const StyledPlaceholderContainer = styled.div`
    background: ${({ theme }) => theme.palette.background.main};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageContainer = styled.div`
    overflow: hidden;
${({ theme }) => css`
        width: ${theme.spacing.get(11)};
        height: ${theme.spacing.get(11)};
        border-radius: 50%;
    `}
`;

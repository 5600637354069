import { CurrencyCode } from '../../common';

export enum PaymentMethodType {
  Fiat = 'fiat',
  Crypto = 'crypto',
}

export interface PaymentMethodDTO {
  id: string;
  name: string;
  type: PaymentMethodType;
  image: string;
  currency: CurrencyCode;
}

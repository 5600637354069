import React, { FC, useMemo } from 'react';
import { CurrencyCode, Offer, defaultPlan } from '@pixy/core';
import { useTranslation } from 'react-i18next';
import { SubscribePlanCard } from '@pixy/web-base';
import { Skeleton } from '@pixy/ui';
import { SliderContainer, StyledSlider } from './styled';

interface PlansBoardProps {
  plans: Offer[] | null;
  currentPlanId: number | null;
  currency: CurrencyCode;
  onClick: (id: number) => void;
}

export const PlansBoard: FC<PlansBoardProps> = ({
  plans,
  currency,
  currentPlanId,
  onClick,
}) => {
  const { t } = useTranslation();

  const freePlan: Offer = useMemo(() => {
    return {
      ...Offer.fromDTO(defaultPlan),
      isDefaultPlan: true,
      isPackage: false,
      isPlan: true,
      title: t('wallet.manage.base.name'),
    };
  }, [t]);

  const planCards = useMemo(() => {
    if (!plans || !plans.length) {
      return [];
    }
    const updatedPlans = [...plans, freePlan];

    return updatedPlans
      ?.filter((item) => item.isPlan)
      ?.map((plan) => {
        return (
          <SubscribePlanCard
            key={plan.id}
            currency={currency}
            item={plan}
            isActive={currentPlanId === plan.id}
            onClick={() => onClick(plan.id)}
          />
        );
      });
  }, [currency, currentPlanId, freePlan, onClick, plans]);

  if (!planCards.length) {
    return <Skeleton height="200px" />;
  }

  const sliderSettings = {
    centerMode: true,
    infinite: true,
    dots: true,
    centerPadding: '12px',
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
  };

  return (
    <SliderContainer>
      <StyledSlider {...sliderSettings}>
        {planCards}
      </StyledSlider>
    </SliderContainer>
  );
};

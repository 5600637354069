import { Stack } from '@pixy/ui';
import Slider from 'rc-slider';
import styled, { css } from 'styled-components';

export const StyledPackagesBoardContainer = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.spacing.get(4)};
    padding: ${theme.spacing.get(4)};
    background: linear-gradient(
      270deg,
      ${theme.palette.neutral60} 0%,
      ${theme.palette.neutral80} 66.93%
    );
  `}
`;

export const ActionBlock = styled(Stack)`
  ${({ theme }) => css`
    max-width: 100%;
    margin-top: ${theme.spacing.get(4)};
  `}
`;

export const CurrencyIconHolder = styled.div`
    display: flex;
    align-items: center;
    ${({ theme }) => css`
        font-size: ${theme.spacing.get(5)};
        color: ${theme.palette.neutral10};
    `}
`;

export const OptionsSelectorContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.get(4)};
    min-height: ${theme.spacing.get(9)};
    display: flex;
    align-items: center;
  `}
`;

export const StyledSlider = styled(Slider)`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    touch-action: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .rc-slider-rail {
      position: absolute;
      margin: 0 auto;
      width: calc(100% + ${theme.spacing.get(1)});
      height: ${theme.spacing.get(1.5)};
      background-color: ${theme.palette.neutral40};
      border-radius: ${theme.spacing.get(0, 5, 5, 0)};
    }

    .rc-slider-track {
      position: absolute;
      height: ${theme.spacing.get(1.5)};
      background-color: ${theme.palette.primary100};
      border-radius: ${theme.spacing.get(0, 5, 5, 0)};

      &:before{
        content: '';
        width: ${theme.spacing.get(1)};
        height: ${theme.spacing.get(1.5)};
        position: absolute;
        left: -${theme.spacing.get(1)};
        border-radius: ${theme.spacing.get(5, 0, 0, 5)};
        background-color: ${theme.palette.primary100};
      }
    }

    .rc-slider-handle {
      position: absolute;
      width: ${theme.spacing.get(10)};
      height: ${theme.spacing.get(10)};
      margin-top: -${theme.spacing.get(5)};
      cursor: -webkit-grab;
      cursor: grab;
      touch-action: pan-x;
      transition: all 0.2s;

      &:after {
        content: "";
        position: absolute;
        width: ${theme.spacing.get(3)};
        height: ${theme.spacing.get(3)};
        background-color: ${theme.palette.primary100};
        top: 50%;
        margin-top: -${theme.spacing.get(0.75)};
        left: 50%;
        margin-left: -${theme.spacing.get(1.75)};
        border-radius: 50%;
        transition: all 0.2s;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:active {
        margin-top: -${theme.spacing.get(5)};
        cursor: -webkit-grabbing;
        cursor: grabbing;

        &:after{
          width: ${theme.spacing.get(5)};
          height: ${theme.spacing.get(5)};
          margin-top: -${theme.spacing.get(1.75)};
          margin-left: -${theme.spacing.get(2.75)};
        }

      }
    }

    .rc-slider-mark {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
    }

    .rc-slider-mark-text {
      ${theme.typography.textXS};
      color: ${theme.palette.neutral10};
      position: absolute;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
    }

    .rc-slider-step {
      position: absolute;
      width: 100%;
      height: ${theme.spacing.get(1.5)};
      background: transparent;
      pointer-events: none;
    }

    .rc-slider-dot {
      position: absolute;
      bottom: -${theme.spacing.get(0.75)};
      width: ${theme.spacing.get(0.5)};
      height: ${theme.spacing.get(3)};
      vertical-align: middle;
      border-radius: ${theme.spacing.get(3, 3, 3, 3)};
      background-color: ${theme.palette.neutral40};
      cursor: pointer;

      &-active {
        background-color: ${theme.palette.primary100};
      }
    }
  `}
`;

import React, { FC, ReactNode, useMemo } from 'react';
import { UserContext, UserContextValue } from './UserContext';
import { useLoadCurrentUser } from '../../hooks';

export interface UserContainerProps {
  children: ReactNode;
}

export const UserContainer: FC<UserContainerProps> = (props) => {
  const { children } = props;
  const {
    result: currentUser,
    isLoading,
    error,
    request: refresh,
  } = useLoadCurrentUser();

  const contextValue: UserContextValue = useMemo(() => {
    return {
      currentUser,
      isLoading,
      error,
      refresh,
    };
  }, [
    currentUser,
    isLoading,
    error,
    refresh,
  ]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

import { createContext } from 'react';
import { Balance } from '../../models';
import { RequestError } from '../../../httpClient';

export interface WalletContextValue {
  balance: Balance | null;
  balanceIsLoading: boolean;
  balanceError: Error | RequestError | null;
  refreshBalance: () => void;
}

export const WalletContext = createContext<WalletContextValue>(
  {} as WalletContextValue,
);

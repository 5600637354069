import { useMemo } from 'react';
import { RequestError } from '../../../httpClient';
import { Offer } from '../../models';
import { useOffersContext } from './useOffersContext';

export const useOffer = (id: Offer['id']): {
  offer: Offer | null;
  isLoading: boolean;
  error: Error | RequestError | null;
} => {
  const { offers, isLoading, error } = useOffersContext();

  const offer = useMemo(() => {
    if (offers) {
      return offers.find((x) => x.id === id) || null;
    }
    return null;
  }, [offers]);

  return {
    offer,
    isLoading,
    error,
  };
};

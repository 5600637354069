/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';
import {
  Button, ButtonSize, ThemeTypographyKey, Typography,
} from '@pixy/ui';
import { Link } from 'react-router-dom';
import { StyledTitleWrapper, StyledTitleWrapperProps } from './styled';

interface TitleWrapperProps extends StyledTitleWrapperProps {
  title: string;
  titleVariant?: ThemeTypographyKey;
  linkVariant?: ButtonSize;
  href?: string;
  buttonText?: string;
}

export const TitleWrapper: FC<TitleWrapperProps> = ({
  title,
  titleVariant,
  linkVariant,
  href,
  buttonText,
  withOffset,
}) => {
  return (
    <StyledTitleWrapper withOffset={withOffset}>
      <Typography variant={titleVariant ?? 'headerXS'}>{title}</Typography>
      {href && buttonText && (
        <Button
          // @ts-ignore
          as={Link}
          size={linkVariant}
          to={href}
          variant="text"
          color="primary"
        >
          {buttonText}
        </Button>
      )}
    </StyledTitleWrapper>
  );
};

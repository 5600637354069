import { format } from 'date-fns-tz';
import { useCallback } from 'react';
import { DateFormat } from '../types';
import { DateFormatMapping, UserLocaleMapping } from '../const';

export interface DateFormatterParams {
  dateFormat?: DateFormat,
  locale?: string
}

type UseDateFormatterHook = {
  formatDate: (timestamp: number | Date) => string
}

export const useDateFormatter = ({
  dateFormat = 'dayFirstFull',
  locale = 'ru',
}: DateFormatterParams): UseDateFormatterHook => {
  const localeMapped = UserLocaleMapping[locale];
  const dateFormatMapped = DateFormatMapping[dateFormat];

  const formatDate = useCallback((timestamp: number | Date) => {
    return format(timestamp, dateFormatMapped, { locale: localeMapped });
  }, [dateFormatMapped, localeMapped]);

  return {
    formatDate,
  };
};

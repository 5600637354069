import React, { useCallback, useState } from 'react';
import {
  AccordionContainer, AccordionContent, AccordionHeader, IconWrapper, StyledAccordeonProps,
} from './styled';
import { CaretRightIcon } from '../Icons';
import { Typography } from '../Typography';

export interface AccordionProps extends StyledAccordeonProps{
  children: React.ReactNode;
  title: string;
  opened?:boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  children, title, color, padding, opened = false,
}) => {
  const [isOpen, setIsOpen] = useState(opened);

  const toggleAccordion = useCallback(() => {
    setIsOpen((value) => !value);
  }, [setIsOpen]);

  return (
    <AccordionContainer isOpen={isOpen} color={color} padding={padding}>
      <AccordionHeader onClick={toggleAccordion}>
        <IconWrapper>
          <CaretRightIcon />
        </IconWrapper>
        <Typography variant="textXSBold">{title}</Typography>
      </AccordionHeader>
      <AccordionContent>
        {children}
      </AccordionContent>
    </AccordionContainer>
  );
};

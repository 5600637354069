import { apiClient } from '../../api';
import { Offer } from '../models';

export interface ApplyPromotionData {
  code: string;
}

export const applyPromotion = async (data: ApplyPromotionData): Promise<Offer> => {
  const response = await apiClient.request({
    path: '/api/v1/promotion/apply',
    method: 'POST',
    body: data,
    withAuth: true,
  });

  return Offer.fromDTO(response.data);
};

import { CurrencyCode } from '@pixy/core';
import { EuroIcon, RubleIcon } from '@pixy/ui';
import React, { ComponentType, FC } from 'react';

const currencyIcons: Record<string, ComponentType> = {
  [CurrencyCode.EUR]: EuroIcon,
  [CurrencyCode.RUB]: RubleIcon,
};

export interface CurrencyIconProps {
  code: CurrencyCode;
}

export const CurrencyIcon: FC<CurrencyIconProps> = (props) => {
  const { code } = props;

  if (code in currencyIcons) {
    const Component = currencyIcons[code];
    return <Component />;
  }

  return (
    <>
      {code}
    </>
  );
};

import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Order } from '../models';

export interface LoadOrdersData extends RequestListParams {}

export const loadOrders = async (
  data: LoadOrdersData = {},
): Promise<RequestPagingResponse<Order>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/order',
    method: 'GET',
    params,
    withAuth: true,
  });

  return apiResponsePagingMapper<Order>(response.data, (x) => Order.fromDTO(x));
};

import { createContext } from 'react';
import { Offer } from '../../models';
import { RequestError } from '../../../httpClient';

export interface OffersContextValue {
  offers: Array<Offer> | null;
  isLoading: boolean;
  error: Error | RequestError | null;
  refresh: () => void;
}

export const OffersContext = createContext<OffersContextValue>(
  {} as OffersContextValue,
);

import {
  Image,
  SettingsIcon, Skeleton, Stack,
} from '@pixy/ui';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWalletContext } from '@pixy/core';
import {
  IconHolder,
  ImageContainer,
  LinkContainer,
  StyledActionBlock,
  StyledAmountBlock,
  StyledAmountTitle,
  StyledBoardContainer,
  StyledDescriptionBlock,
  StyledPlanTitle,
  StyledSubtitle,
} from './styled';

export interface WalletBoardProps {
  addGenerationsRoute?: string;
}

export const WalletBoard: FC<WalletBoardProps> = ({ addGenerationsRoute }) => {
  const { t } = useTranslation();
  const { balance, balanceIsLoading } = useWalletContext();

  const currentPlan = balance?.currentOffer ?? null;

  const planTitle = currentPlan?.isDefaultPlan ? t('wallet.manage.base.name') : currentPlan?.title;

  const totalGenerations = useMemo(() => {
    if (balance) {
      const payedActions = balance.availableTasks.paid_actions ?? 0;
      const freeActions = balance.availableTasks.total_free ?? 0;

      return payedActions + freeActions;
    }
    return 0;
  }, [balance]);

  if (!currentPlan || balanceIsLoading) {
    return (
      <StyledBoardContainer>
        <Stack direction="row" alignItems="stretch" gap={2}>
          <Skeleton height="140px" />
          <Stack style={{ width: '100%' }} alignItems="stretch" gap={2}>
            <Skeleton height="80px" />
            <Skeleton height="52px" />
          </Stack>
        </Stack>
      </StyledBoardContainer>
    );
  }

  return (
    <StyledBoardContainer>
      <Stack direction="row" alignItems="stretch" gap={2}>
        <StyledDescriptionBlock>
          <ImageContainer>
            <Image src={currentPlan.imageUrl} />
          </ImageContainer>
          <Stack gap={1} style={{ position: 'relative', zIndex: 1 }}>
            <StyledSubtitle>
              {t('wallet.manage.terms.plan')}
            </StyledSubtitle>
            <StyledPlanTitle>
              {planTitle}
            </StyledPlanTitle>
          </Stack>
        </StyledDescriptionBlock>
        <Stack style={{ width: '100%' }} alignItems="stretch" gap={2}>
          <StyledAmountBlock>
            <Stack gap={2}>
              <StyledSubtitle>
                {t('wallet.manage.terms.generationsLeft')}
              </StyledSubtitle>
              <StyledAmountTitle>
                {totalGenerations}
              </StyledAmountTitle>
            </Stack>
          </StyledAmountBlock>
          <StyledActionBlock>
            <LinkContainer to={addGenerationsRoute ?? '/'}>
              <Stack direction="row" gap={2} alignItems="center">
                <IconHolder>
                  <SettingsIcon fontSize={16} />
                </IconHolder>
                <StyledSubtitle>
                  {t('wallet.manage.terms.manage')}
                </StyledSubtitle>
              </Stack>
            </LinkContainer>
          </StyledActionBlock>
        </Stack>
      </Stack>
    </StyledBoardContainer>
  );
};

import styled from 'styled-components';

export const StyledCardContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.get(2)} ${({ theme }) => theme.spacing.get(5)};
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  
  &:hover {
    background: ${({ theme }) => theme.palette.neutral80}
  }
`;

export const StyledCardInfoBlock = styled.div`
  width: 100%; 
  height: 100%;
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.spacing.get(3)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledTagContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.get(2)};
  gap: 4px;
  justify-self: flex-end;
`;

export const StyledImageContainer = styled.div`
  width: 63px;
  flex-shrink: 0;
`;

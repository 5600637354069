import styled, { css } from 'styled-components';

export const StyledNavigationWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  ${({ theme }) => css`
    padding: ${theme.spacing.get(0, 4)};
    background: ${theme.palette.background.main};
  `}
`;

export const StylesTitleWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.get(4)};
`;

import React, { FC, useCallback, useState } from 'react';
import { Skeleton } from '../Skeleton';
import { StyledImage, StyledImageProps } from './styled';

type ImageProps = StyledImageProps & {
    src: string;
    placeholder?:string,
    alt?: string;
    onError?: () => void;
}

export const Image:FC<ImageProps> = ({
  src,
  alt,
  onError,
  rounded,
  placeholder,
}) => {
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);

  const handleImageLoad = useCallback(() => {
    setLoading(false);
    setImageLoaded(true);
  }, [setImageLoaded, setLoading]);

  const handleImageError = useCallback(() => {
    setLoading(false);
    if (placeholder) {
      setImgSrc(placeholder);
    }
    if (onError) {
      onError();
    }
  }, [setLoading, onError]);

  return (
    <>
      {loading && <Skeleton />}
      <StyledImage
        src={imgSrc}
        alt={alt}
        loaded={imageLoaded}
        rounded={rounded}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </>
  );
};

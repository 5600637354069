import { Expose, plainToClass } from 'class-transformer';
import { PresetDTO } from '../types';

export class Preset {
  id: number;

  name: string;

  config: string;

  image: string;

  @Expose({ name: 'is_public' })
    isPublic: PresetDTO['is_public'];

  @Expose({ name: 'user_id' })
    userId: PresetDTO['user_id'];

  static fromDTO(dto: PresetDTO): Preset {
    return plainToClass(Preset, dto);
  }
}

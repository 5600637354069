const tg = Telegram.WebApp;

export function useTelegram() {
  const onClose = () => {
    tg.close();
  };

  return {
    tg,
    onClose,
    queryId: tg.initDataUnsafe?.query_id,
    userId: tg.initDataUnsafe?.user?.id,
    theme: tg.colorScheme,
    initData: tg.initData,
    locale: tg.initDataUnsafe.user?.language_code === 'en' ? 'en' : 'ru',
  };
}

import { useCallback, useState } from 'react';
import { authService } from '../services';
import { TelegramSigninData, telegramSignin } from '../network';

export const useTelegramSignin = (): {
  result: boolean | null,
  isLoading: boolean,
  error: Error | null,
  request: (data: TelegramSigninData) => void,
} => {
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: TelegramSigninData) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await telegramSignin(data);
        await authService.setAuthData(response);
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }

      setIsLoading(false);
    },
    [],
  );

  return {
    result,
    isLoading,
    error,
    request,
  };
};

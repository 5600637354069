import styled, { css } from 'styled-components';

export const StyledPromocodesContainer = styled.div`
    width: 100%;    
    ${({ theme }) => css`
        padding: ${theme.spacing.get(0, 0.5)};
    `}
`;

export const StyledTitleContainer = styled.div`
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.get(2)};
`;

import { Type, plainToClass } from 'class-transformer';
import { TaskDTO, TaskDTOData, TaskDTOResult } from '../types';

export class Task implements TaskDTO {
  id: number;

  parent_id: number | null;

  execution_time: number | null;

  @Type(() => Date)
    created: string;

  @Type(() => Date)
    finished: string;

  data: TaskDTOData;

  result: TaskDTOResult | null;

  static fromDTO(dto: TaskDTO): Task {
    return plainToClass(Task, dto);
  }

  get status(): 'success' | 'unknown' {
    if (this.result?.progress === 'done') {
      return 'success';
    }
    return 'unknown';
  }
}

import { apiClient } from '../../api';
import { Offer } from '../models';

export const loadOffers = async (): Promise<Array<Offer>> => {
  const response = await apiClient.request({
    path: '/api/v1/offer',
    method: 'GET',
    withAuth: true,
  });

  if (Array.isArray(response.data)) {
    return response.data.map((x) => Offer.fromDTO(x));
  }

  return [];
};

import styled, { css } from 'styled-components';

export const BoardContainer = styled.div`
    ${({ theme }) => css`
        padding: ${theme.spacing.get(0, 4)};
    `}
`;

export const TaskLinkContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${({ theme }) => css`
        gap: ${theme.spacing.get(3)}; 
        padding: ${theme.spacing.get(4)};
        border-radius: ${theme.spacing.get(4)};
        background: linear-gradient(
            270deg,
            ${theme.palette.neutral60} 0%,
            ${theme.palette.neutral80} 66.93%
        );
    `}
`;

export const IconHolder = styled.div`
    display: flex;
    align-items: center; 
    justify-content: center;
    ${({ theme }) => css`
       width: ${theme.spacing.get(5)};
       height: ${theme.spacing.get(5)};
       font-size: ${theme.spacing.get(3)};
    `}
`;

export const OptionContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    ${({ theme }) => css`
       color: ${theme.palette.neutral10};
       font-size: ${theme.spacing.get(5)};
       padding: ${theme.spacing.get(4, 0)};
       gap: ${theme.spacing.get(1)};
    `}
`;

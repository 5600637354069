import styled, { css } from 'styled-components';

export const StyledTaskContainer = styled.div`
  width: 100%;
  ${({ theme }) => css`
    background: ${theme.palette.neutral80};
    padding: ${theme.spacing.get(4)};
    border-radius: ${theme.spacing.get(4)};
  `}
`;

export const StyledTaskSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  ${({ theme }) => {
    return css`
      padding: ${theme.spacing.get(3, 0)};
      border-top: 1px solid ${theme.palette.neutral60};
      margin-top: ${theme.spacing.get(4)};
    `;
  }}
`;

export const TagHolder = styled.div`
  flex-shrink: 0;
  margin-left: ${({ theme }) => theme.spacing.get(5)};
`;

import { apiClient } from '../../api';
import { User } from '../models';

export const loadCurrentUser = async (): Promise<User> => {
  const response = await apiClient.request({
    path: '/api/v1/users/me',
    withAuth: true,
  });

  return User.fromDTO(response.data);
};

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { TasksScreeen } from '../../tasks';

export const TasksRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={<TasksScreeen />}
      />
    </Routes>
  );
};

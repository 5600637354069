import styled, { css } from 'styled-components';

export interface StyledImageProps {
    loaded?: boolean;
    rounded?: boolean;
}

export const StyledImage = styled.img<StyledImageProps>`
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    ${({ theme, rounded }) => rounded && css`
        border-radius: ${theme.spacing.get(3)};
    `}

    ${({ loaded }) => loaded && css`
        opacity: 1;
    `}
`;

import { createContext } from 'react';
import { Currency, CurrencyCode } from '../../types';

export interface CurrencyContextValue {
  currency: Currency;
  setCurrency: (code: CurrencyCode) => void;
}

export const CurrencyContext = createContext<CurrencyContextValue>(
  {} as CurrencyContextValue,
);

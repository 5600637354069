import QueryString from 'qs';
import { apiClient } from '../../api';
import { AuthData } from '../types';

export interface TelegramSigninData {
  hash: string;
  auth_date: number;
  user: {
    id: number;
    first_name: string;
    last_name?: string;
    photo_url?: string;
    username?: string;
  },
  botId?: string | null;
  companyId?: string | null;
}

export const telegramSignin = async (
  data: TelegramSigninData,
): Promise<AuthData> => {
  const {
    hash,
    botId,
    companyId,
    ...rest
  } = data;
  const query = QueryString.stringify(rest);
  const response = await apiClient.request({
    path: botId ? `/api/v1/telegram_bot/login/${botId}` : '/telegram/v1/login/',
    method: 'POST',
    body: {
      hash,
      query,
    },
    withAuth: false,
    headers: {
      'x-company-id': companyId || '',
    },
  });

  return {
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token || null,
  };
};

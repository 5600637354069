import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Stack } from '@pixy/ui';
import { useLoadPresets } from '@pixy/core';
import { StyleCard } from '../StyleCard';

export const PresetList: FC = () => {
  const { t } = useTranslation();

  const { result, request } = useLoadPresets();

  const cards = useMemo(() => {
    return result?.data.map((style) => {
      return <StyleCard key={style.id} item={style} />;
    });
  }, [result]);

  useEffect(() => {
    request();
  }, [request]);

  return (
    <Stack gap={4}>
      <Accordion
        title={t('styles.stylesSections.mutual')}
        padding={{ side: 4, bottom: 4 }}
        opened
      >
        {cards}
      </Accordion>
    </Stack>
  );
};

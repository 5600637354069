import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Stack, Typography } from '@pixy/ui';
import { useTranslation } from 'react-i18next';
import {
  CurrencyCode, Offer, useOffersContext, usePriceMap,
} from '@pixy/core';
import {
  ActionBlock,
  CurrencyIconHolder,
  OptionsSelectorContainer,
  StyledPackagesBoardContainer,
  StyledSlider,
} from './styled';
import { CurrencyIcon } from '../../shared';

export interface PackagesBoardProps {
  onClick: (id: number) => void;
}

export const PackagesBoard: FC<PackagesBoardProps> = ({
  onClick,
}) => {
  const { offers } = useOffersContext();
  const { t } = useTranslation();
  const [choosenPackage, setChoosenPackage] = useState<Offer | null>(null);

  const packagesOffers = useMemo(
    () => offers?.filter((item) => item.isPackage)?.sort((a, b) => a.amount - b.amount) ?? [],
    [offers],
  );

  const packagePrice = usePriceMap(choosenPackage && choosenPackage.prices);

  const offersData = useMemo(() => {
    return packagesOffers.reduce(
      (acc, packageItem, index) => {
        const defaultValue = packagesOffers.length < 2
          ? 0
          : Math.round(packagesOffers.length / 2) - 1;

        return {
          ...acc,
          min: 0,
          max: index,
          defaultValue,
          marks: {
            ...acc.marks,
            [index]: packageItem.amount,
          },
        };
      },
      {
        min: 0,
        max: 0,
        defaultValue: 0,
        marks: {},
      },
    );
  }, [offers]);

  const onPackageChoose = useCallback(
    (value: number) => {
      const choosenAmount = offersData.marks[value];
      const result = offers?.filter((item) => item.amount === choosenAmount);
      if (result?.length) {
        setChoosenPackage(result[0]);
      }
    },
    [offers],
  );

  const onClickHandler = useCallback(
    (id: number) => {
      if (onClick) {
        onClick(id);
      }
    },
    [onClick],
  );

  useEffect(() => {
    if (packagesOffers && packagesOffers.length) {
      const middleIndex = Math.round(packagesOffers.length / 2) - 1;
      if (middleIndex > 0) {
        setChoosenPackage(packagesOffers[middleIndex]);
      } else {
        setChoosenPackage(packagesOffers[0]);
      }
    }
  }, [packagesOffers]);

  return (
    <StyledPackagesBoardContainer>
      <Stack gap={1}>
        <Typography variant="textMDBold" color="neutral5">
          {t('wallet.manage.packages.boardHeader')}
        </Typography>
        <Typography variant="textXS" color="neutral10">
          {t('wallet.manage.packages.boardDescription')}
        </Typography>
      </Stack>
      {packagesOffers && (
        <OptionsSelectorContainer>
          <StyledSlider
            step={null}
            min={offersData?.min}
            max={offersData?.max}
            marks={offersData?.marks}
            defaultValue={offersData?.defaultValue}
            onChange={onPackageChoose}
          />
        </OptionsSelectorContainer>
      )}
      <ActionBlock
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="headerXS">{packagePrice?.value}</Typography>
            <CurrencyIconHolder>
              <CurrencyIcon code={packagePrice?.currency.code ?? CurrencyCode.EUR} />
            </CurrencyIconHolder>
          </Stack>
          <Typography>
            {t('pluralize.generation', { count: choosenPackage?.amount })}
          </Typography>
        </Stack>
        <Button
          size="xs"
          onClick={() => choosenPackage && onClickHandler(choosenPackage.id)}
        >
          {t('wallet.manage.terms.buy')}
        </Button>
      </ActionBlock>
    </StyledPackagesBoardContainer>
  );
};

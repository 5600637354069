import { ThemePaletteKey, Typography } from '@pixy/ui';
import styled, { css } from 'styled-components';

export const PaymentCardContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.get(3.5, 0)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral80};
`;

export const StatusIconWrapper = styled.div<{ color: ThemePaletteKey }>`
  color: ${({ theme, color }) => theme.palette[color]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CurrencyIconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.neutral5};
    font-size: ${theme.spacing.get(3.5)};
    padding-top: ${theme.spacing.get(0.75)};
  `}
`;

export const TimeCell = styled(Typography)`
  ${({ theme }) => css`
    max-width: ${theme.spacing.get(17.5)};


    ${theme.breakpoints.up('md')} {
      flex-grow: 0;
      max-width: unset;
      width: ${theme.spacing.get(25)};
    }
  `}
`;

export const PurchaseInfoCell = styled(Typography)`
  flex-grow: 1;

  ${({ theme }) => css`
    max-width: ${theme.spacing.get(25)};

    ${theme.breakpoints.up('md')} {
      flex-grow: 0;
      max-width: unset;
      width: ${theme.spacing.get(35)};
      margin: ${theme.spacing.get(0, 22)};
    }
  `}
`;

export const PriceCell = styled(Typography)`
  margin-left: auto;
`;

import { useMemo } from 'react';
import { CurrencyCode, CurrencyPriceMap } from '../types';
import { currenciesDict } from '../data';
import { Price } from '../models';
import { useCurrencyContext } from '../containers';

export const usePriceMap = (
  priceMap: CurrencyPriceMap | null,
  currencyCode?: CurrencyCode,
  fallbackCurrency = CurrencyCode.EUR,
): Price | null => {
  const context = useCurrencyContext();

  const result = useMemo(() => {
    if (!priceMap) {
      return null;
    }

    if (currencyCode && currencyCode in currenciesDict && currencyCode in priceMap) {
      return new Price(priceMap[currencyCode], currencyCode);
    }
    if (context?.currency && context.currency.code in priceMap) {
      return new Price(priceMap[context.currency.code], context.currency);
    }
    if (fallbackCurrency && fallbackCurrency in currenciesDict && fallbackCurrency in priceMap) {
      return new Price(priceMap[fallbackCurrency], fallbackCurrency);
    }
    const availableCurrency = Object.keys(priceMap)[0];
    if (availableCurrency) {
      return new Price(priceMap[availableCurrency], availableCurrency as CurrencyCode);
    }

    return null;
  }, [priceMap, currencyCode, context?.currency, fallbackCurrency]);

  return result;
};

import styled, { css } from 'styled-components';
import { AlertAppearanceDirection, AlertVariant } from './types';

export interface StyledAlertProps {
  variant?: AlertVariant;
  direction?: AlertAppearanceDirection;
  opened?: boolean;
}

export const IconHolder = styled.div<{ position?: 'start' | 'end' }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.get(3)};
  flex-shrink: 0;
  cursor: pointer;
  pointer-events: auto;

  ${({ theme, position }) => {
    if (position === 'start') {
      return css`
        padding-left: ${theme.spacing.get(3)};
        &:first-child {
          padding-left: ${theme.spacing.get(4)};
        }
      `;
    }
    if (position === 'end') {
      return css`
        padding-right: ${theme.spacing.get(3)};
        &:last-child {
          padding-right: ${theme.spacing.get(4)};
        }
      `;
    }
    return '';
  }}
`;

export const TextContainer = styled.div`
  flex: 1;
`;

export const StyledAlertWrapper = styled.div<StyledAlertProps>`
  width: 100%;
  max-width: 90%;
  position: absolute;
  z-index: 9999;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  pointer-events: auto;

  ${({ theme, direction = 'bottom-center', opened = false }) => {
    const spacing = theme.spacing.get(4);
    let positionStyles = '';

    switch (direction) {
      case 'top-left':
        positionStyles = `
                top: ${spacing};
                left: ${spacing};
                transform: translateY(${
  opened ? '0' : `calc(-100% - ${spacing})`
});
            `;
        break;
      case 'top-right':
        positionStyles = `
                top: ${spacing};
                right: ${spacing};
                transform: translateY(${
  opened ? '0' : `calc(-100% - ${spacing})`
});
            `;
        break;
      case 'top-center':
        positionStyles = `
                top: ${spacing};
                left: 50%;
                transform: translateX(-50%) translateY(${
  opened ? '0' : `calc(-100% - ${spacing})`
});
            `;
        break;
      case 'bottom-left':
        positionStyles = `
                bottom: ${spacing};
                left: ${spacing};
                transform: translateY(${
  opened ? '0' : `calc(100% + ${spacing})`
});
            `;
        break;
      case 'bottom-right':
        positionStyles = `
                bottom: ${spacing};
                right: ${spacing};
                transform: translateY(${
  opened ? '0' : `calc(100% + ${spacing})`
});
            `;
        break;
      case 'bottom-center':
      default:
        positionStyles = `
                bottom: ${spacing};
                left: 50%;
                transform: translateX(-50%) translateY(${
  opened ? '0' : `calc(100% + ${spacing})`
});
            `;
        break;
    }

    return css`
      ${positionStyles}
    `;
  }}

  ${({ theme, variant = 'gray' }) => {
    let textColor = theme.palette.neutral10;
    let background = theme.palette.neutral60;

    if (variant === 'danger') {
      textColor = theme.palette.danger100;
      background = theme.palette.danger10;
    }

    if (variant === 'warning') {
      textColor = theme.palette.warning100;
      background = theme.palette.warning10;
    }

    if (variant === 'success') {
      textColor = theme.palette.success100;
      background = theme.palette.success10;
    }

    if (variant === 'black') {
      textColor = theme.palette.neutral100;
      background = theme.palette.neutral5;
    }

    return css`
      color: ${textColor};
      background: ${background};
      padding: ${theme.spacing.get(3, 4)};
      border-radius: ${theme.spacing.get(3)};
    `;
  }}
`;

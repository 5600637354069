import { Theme } from '@pixy/ui';
import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{theme?: Theme}>`
  body {
    margin: 0;
    padding: 0;
    ${({ theme }) => css`
      font-family: ${theme.typography.fontFamily};
      color: ${theme.palette.neutral5};
      background-color: ${theme.palette.background.main};
  `};
  }

  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
    touch-action: pan-y;
  }
`;

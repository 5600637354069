import React, { FC } from 'react';
import { Loader } from '@pixy/ui';
import { useTranslation } from 'react-i18next';
import { useLoadOrders } from '@pixy/core';
import {
  OrdersBoard, PromoCodesBoard, WalletBoard,
} from '@pixy/web-base';

import { AppTabs, TWARoutes } from '../../../navigation';
import { PageContainer, TitleWrapper } from '../../../shared';

import {
  StyledOrdersLoadingContainer,
  StyledBoardContainer,
  StyledWalletScreenContainer,
  OrdersContainer,
  StyledNavigationWrapper,
} from './styled';

export const WalletScreen: FC = () => {
  const { t } = useTranslation();
  const { result: orders, isLoading: ordersIsLoading } = useLoadOrders();

  const paymentsHistory = orders ? orders.data.slice(0, 5) : null;

  return (
    <PageContainer>
      <StyledNavigationWrapper>
        <AppTabs />
      </StyledNavigationWrapper>
      <StyledWalletScreenContainer>
        <TitleWrapper title={t('wallet.pageHeader')} />
        <StyledBoardContainer>
          <WalletBoard addGenerationsRoute={TWARoutes.manage} />
        </StyledBoardContainer>
        <StyledBoardContainer>
          <PromoCodesBoard />
        </StyledBoardContainer>

        {ordersIsLoading && (
        <StyledOrdersLoadingContainer>
          <Loader size={50} />
        </StyledOrdersLoadingContainer>
        )}

        {!ordersIsLoading && (
          <>
            <TitleWrapper
              title={t('wallet.history.pageHeader')}
              titleVariant="textSMBold"
              linkVariant="xs"
              buttonText={t('wallet.historyPageLink')}
              href={TWARoutes.history}
            />
            <OrdersContainer>
              <OrdersBoard orders={paymentsHistory} />
            </OrdersContainer>
          </>
        )}
      </StyledWalletScreenContainer>
    </PageContainer>
  );
};

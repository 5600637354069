const STORAGE_ID_KEY = 'TWA_STATE_ID';
const STORAGE_VALUE_KEY = 'TWA_STATE_PARAMS';

export const getQueryConfig = () => {
  const tgQueryId = Telegram?.WebApp?.initDataUnsafe?.query_id;
  const storageQueryId = localStorage.getItem(STORAGE_ID_KEY);
  const storageParams = localStorage.getItem(STORAGE_VALUE_KEY);

  let params = window.location.search;

  if (tgQueryId === storageQueryId && storageParams) {
    params = storageParams;
  } else if (tgQueryId && params) {
    localStorage.setItem(STORAGE_ID_KEY, tgQueryId);
    localStorage.setItem(STORAGE_VALUE_KEY, params);
  }

  return new URLSearchParams(params);
};

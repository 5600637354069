import styled from 'styled-components';

export const StyledSnackBarContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;    
    top: 0;
    left: 0;
    pointer-events: none;
`;

import { useCallback, useState } from 'react';
import QueryString from 'qs';
import { authService } from '../services';
import { TelegramSigninData, telegramSignin } from '../network';

export interface TWASigninData {
  query: string;
  botId?: string | null;
  companyId?: string | null;
}

// TODO: make deprecated after API updated
export const useTWASignin = (): {
  result: boolean | null,
  isLoading: boolean,
  error: Error | null,
  request: (data: TWASigninData) => void,
} => {
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: TWASigninData) => {
      setIsLoading(true);
      setError(null);

      try {
        const requestData: TelegramSigninData = QueryString.parse(data.query) as never;
        const response = await telegramSignin({
          botId: data.botId,
          companyId: data.companyId,
          ...requestData,
        });
        await authService.setAuthData(response);
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('Unknown error'));
        }
      }

      setIsLoading(false);
    },
    [],
  );

  return {
    result,
    isLoading,
    error,
    request,
  };
};

export enum OrderStatus {
  New = 1,
  Pending = 2,
  Success = 3,
  Error = 4,
  Cancelled = 5,
}

export interface OrderDTO {
  id: number;
  amount: number;
  amount_eur: number;
  status: OrderStatus;
  offer_id: number;
  created: string;
}

import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Task } from '../models';

export interface LoadTasksFilters {
  created__lt?: string;
  created__lte?: string;
  created__gt?: string;
  created__gte?: string;
}

export interface LoadTasksData extends RequestListParams<LoadTasksFilters> {}

export const loadTasks = async (
  data: LoadTasksData = {},
): Promise<RequestPagingResponse<Task>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/admin/v1/task/',
    method: 'GET',
    params,
    withAuth: true,
  });

  return apiResponsePagingMapper<Task>(response.data, (x) => Task.fromDTO(x));
};

import { currenciesDict } from '../data';
import { Currency, CurrencyCode } from '../types';

export class Price {
  rawValue: number;

  currency: Currency;

  constructor(value: number, currency: Currency | CurrencyCode) {
    this.rawValue = value;
    if (typeof currency === 'object') {
      this.currency = currency;
    } else if (currency in currenciesDict) {
      this.currency = currenciesDict[currency];
    } else {
      this.currency = {
        code: currency,
        decimalPlaces: 0,
        displayDecimals: 2,
        symbol: currency.toUpperCase(),
        symbolPlacement: 'start',
      };
    }
  }

  get value(): number {
    if (this.currency.decimalPlaces > 0) {
      return this.rawValue / (10 ** 2);
    }
    return this.rawValue;
  }

  get formattedValue(): string {
    // #TODO Split into thousands
    return this.value.toFixed(this.currency.displayDecimals);
  }

  get formattedCurrencyValue(): string {
    if (this.currency.symbolPlacement === 'start') {
      return `${this.currency.symbol} ${this.formattedValue}`;
    }
    if (this.currency.symbolPlacement === 'end') {
      return `${this.formattedValue} ${this.currency.symbol}`;
    }
    return this.formattedValue;
  }
}

export const createStylePresets = [
  {
    id: 1,
    preset: '--style',
  },
  {
    id: 2,
    preset: '--v',
  },
  {
    id: 3,
    preset: '--niji',
  },
  {
    id: 4,
    preset: '--no',
  },
  {
    id: 5,
    preset: '--aspect',
  },
  {
    id: 6,
    preset: '--sameseed',
  },
];

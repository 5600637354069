import { useMemo } from 'react';
import { CurrencyCode } from '../types';
import { Price } from '../models';

export const usePrice = (
  price: number,
  currency: CurrencyCode,
): Price => {
  const result = useMemo(() => {
    return new Price(price, currency);
  }, [price, currency]);

  return result;
};

import { BrushIcon, GamepadIcon, WalletIcon } from '@pixy/ui';

export const TWARoutes = {
  styles: '/styles',
  stylesCreate: '/styles/create',

  wallet: '/wallet',
  manage: '/wallet/buy-generations',
  payments: '/wallet/make-payment',
  history: '/wallet/payments-history',

  tasks: '/tasks',
};

type Navigation = {
  id: 'wallet' | 'style' | 'task',
  icon: React.ReactNode,
  path: string
}

export const navigationTabs: Navigation[] = [
  {
    id: 'wallet',
    icon: <WalletIcon width={20} height={20} />,
    path: TWARoutes.wallet,
  },
  {
    id: 'style',
    icon: <BrushIcon width={20} height={20} />,
    path: TWARoutes.styles,
  },
  {
    id: 'task',
    icon: <GamepadIcon width={20} height={20} />,
    path: TWARoutes.tasks,
  },
];

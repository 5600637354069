import { Button, FormTextInput, Typography } from '@pixy/ui';
import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  StyledButtonContainer,
  StyledInputContainer,
  StyledModalContainer,
  StyledTitleWrapper,
} from './styled';

export interface PaymentModalProps {
  onSubmit: ({ email }) => void;
}

export const PaymentModal: FC<PaymentModalProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const valueChangeHandler = (text: string) => {
    if (errorMessage) {
      setErrorMessage(null);
    }

    setValue(text);
  };

  const {
    control, register, formState: { errors }, trigger,
  } = useForm();

  const handleButtonClick = useCallback(() => {
    trigger('email')
      .then((isValid) => {
        if (isValid) {
          onSubmit({ email: value });
        } else if (errors?.email?.message) {
          setErrorMessage(errors.email.message as string);
        }
      });
  }, [errors?.email?.message, onSubmit, trigger, value]);

  return (
    <StyledModalContainer>
      <StyledTitleWrapper>
        <Typography>{t('wallet.payments.modal.title')}</Typography>
      </StyledTitleWrapper>
      <StyledInputContainer>
        <FormTextInput
          placeholder="Email"
          fullWidth
          allowClear
          error={errorMessage}
          value={value}
          onChangeText={valueChangeHandler}
          control={control}
          {...register('email', {
            required: t('wallet.payments.modal.required') as string,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i,
              message: t('wallet.payments.modal.notvalid'),
            },
          })}
        />
      </StyledInputContainer>
      <StyledButtonContainer>
        <Button disabled={!value || Boolean(errorMessage)} onClick={handleButtonClick}>
          {t('wallet.payments.modal.send')}
        </Button>
      </StyledButtonContainer>
    </StyledModalContainer>
  );
};

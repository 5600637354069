import styled, { css } from 'styled-components';

export const TypeIconContainer = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.neutral20};
        margin-right: ${theme.spacing.get(3)};
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`;

export const PaymentOptionIconContainer = styled.div`
    ${({ theme }) => css`
        height: ${theme.spacing.get(8)};
        background: #ffffff;
        border-radius: ${theme.spacing.get(3)};
        padding: ${theme.spacing.get(1, 2)};
        display: flex;
        align-items: center;
        justify-content: center;


        & img {
           width: auto;
        }
    `}
`;

export const StyledPaymentCard = styled.div<{disabled: boolean}>`
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    ${({ theme }) => css`
        background: ${theme.palette.neutral80};
        padding: ${theme.spacing.get(4)};
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: ${theme.spacing.get(5)};
        cursor: pointer;
    `}

    ${({ disabled }) => disabled && css`
        opacity: 40%;
        pointer-events: none;
    `}
`;

export const IconWrapper = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.neutral10};
        padding-top: ${theme.spacing.get(1)};
        font-size: ${theme.spacing.get(5)};
    `}
`;

import React, {
  ReactNode,
  useCallback,
  FC,
  memo,
} from 'react';
import ReactDOM from 'react-dom';
import {
  StyledModalContainer,
  StyledModalProps,
  StyledOverlay,
  StyledCloseButtonContainer,
} from './styled';
import { IconButton } from '../IconButton';
import { CloseIcon } from '../Icons';

export interface ModalProps extends StyledModalProps {
  children: ReactNode;
  opened: boolean;
  width?: number;
  topOffset?: number;
  withoutOverlay?: boolean;
  portal?: boolean;
  onClose?: () => void;
}

export const Modal: FC<ModalProps> = memo(({
  children,
  opened,
  width,
  topOffset,
  portal,
  onClose,
  withoutOverlay,
}) => {
  const closeModalHandler = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const modal = (
    <StyledOverlay
      withoutOverlay={withoutOverlay}
      topOffset={topOffset}
      onClick={closeModalHandler}
    >
      <StyledModalContainer width={width} onClick={(e) => e.stopPropagation()}>
        {onClose && (
        <StyledCloseButtonContainer>
          <IconButton color="transparent" onClick={closeModalHandler}>
            <CloseIcon fontSize={24} />
          </IconButton>
        </StyledCloseButtonContainer>
        )}
        {children}
      </StyledModalContainer>
    </StyledOverlay>
  );

  if (!opened) {
    return null;
  }

  if (portal) {
    return ReactDOM.createPortal(modal, document.body);
  }

  return modal;
});

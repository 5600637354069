import {
  apiClient,
  apiRequestListParamsMapper,
  apiResponsePagingMapper,
} from '../../api';
import { RequestListParams, RequestPagingResponse } from '../../common';
import { Preset } from '../models';

export interface LoadPresetFilters {
  name: string;
  is_public: boolean;
}

export interface LoadPresetData extends RequestListParams<LoadPresetFilters> {}

export const loadPreset = async (
  data: LoadPresetData = {
    sort: { field: 'order_value', direction: 'desc' },
  },
): Promise<RequestPagingResponse<Preset>> => {
  const params = apiRequestListParamsMapper(data);
  const response = await apiClient.request({
    path: '/api/v1/preset',
    method: 'GET',
    params,
    withAuth: true,
  });

  return apiResponsePagingMapper<Preset>(response.data, (x) => Preset.fromDTO(x));
};

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateStylesScreen, StylesScreen } from '../../styles';

export const StylesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={<StylesScreen />}
      />
      <Route
        path="/create"
        element={<CreateStylesScreen />}
      />
    </Routes>
  );
};

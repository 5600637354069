import React, {
  ReactNode, useState, useCallback, useEffect,
} from 'react';
import { FCWithAs } from '../../types';
import {
  IconHolder,
  StyledAlertProps,
  StyledAlertWrapper,
  TextContainer,
} from './styled';
import { Typography } from '../Typography';
import { CloseIcon } from '../Icons';

export interface AlertProps extends StyledAlertProps {
  text: string;
  closable?: boolean;
  icon?: ReactNode;
  onClose?: () => void;
}

export const Alert: FCWithAs<'div', AlertProps> = ({
  text,
  opened = false,
  closable,
  icon,
  variant,
  direction,
  onClose,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpened(opened);
    }, 50);
  }, [opened]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setIsOpened(false);
  }, [onClose]);

  return (
    <StyledAlertWrapper opened={isOpened} direction={direction} variant={variant}>
      {icon && <IconHolder position="end">{icon}</IconHolder>}
      <TextContainer>
        <Typography>{text}</Typography>
      </TextContainer>
      {closable && (
        <IconHolder position="start" onClick={handleClose}>
          <CloseIcon />
        </IconHolder>
      )}
    </StyledAlertWrapper>
  );
};

import React, { FC, useMemo } from 'react';
import { Stack, useBreakpoint } from '@pixy/ui';

import { useTranslation } from 'react-i18next';
import {
  CurrencyCode, Order, useOffer, usePrice,
} from '@pixy/core';

import {
  PaymentCardContainer,
  PriceCell,
  PurchaseInfoCell,
  TimeCell,
} from './styled';
import { getIconByStatus } from './const';
import {
  useDateFormatter, useTimeFormatter,
} from '../../../shared';

interface OrderCardProps {
  item: Order;
}

export const OrderCard: FC<OrderCardProps> = ({ item }) => {
  const { t, i18n } = useTranslation();
  const { formatDate } = useDateFormatter({});
  const { formatTime } = useTimeFormatter();
  const isMobile = useBreakpoint('down', 'md');

  const {
    status, offerId, created, currency, amount,
  } = item;

  const { offer } = useOffer(offerId);

  const {
    amount: amountGenerations,
    executionNumber,
    executionPeriod,
  } = offer ?? {};

  const formattedTime = formatDate(created);
  const locale = i18n.language;
  const IconComponent = getIconByStatus(status);
  const price = usePrice(amount, currency ?? ('' as CurrencyCode));

  const purchaseInfo = useMemo(() => {
    if (!amountGenerations && executionPeriod && executionNumber) {
      if (isMobile) {
        return (
          <div>
            {`${t('pluralize.generation', { count: executionNumber })}`}
            <br />
            {`${t('wallet.manage.terms.per')} ${formatTime(executionPeriod)}`}
          </div>
        );
      }
      return `${t('pluralize.generation', { count: executionNumber })} ${t('wallet.manage.terms.per')} ${formatTime(executionPeriod)}`;
    }
    return `${t('pluralize.generation', { count: amountGenerations })}`;
  }, [
    t,
    amountGenerations,
    executionNumber,
    executionPeriod,
    locale,
    isMobile,
  ]);

  return (
    <PaymentCardContainer>
      <Stack
        direction="row"
        gap={4}
        alignItems="center"
        justifyContent="flex-start"
      >
        <TimeCell color="neutral10" variant="textXS">
          {formattedTime}
        </TimeCell>
        <PurchaseInfoCell color="neutral5" variant="textXS">
          {purchaseInfo}
        </PurchaseInfoCell>
        {IconComponent}
        <PriceCell color="neutral5" variant="textXS">
          {price.formattedCurrencyValue}
        </PriceCell>
      </Stack>
    </PaymentCardContainer>
  );
};

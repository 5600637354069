import React, { useState, useEffect } from 'react';
import { Alert, AlertProps } from '../Alert';
import { StyledSnackBarContainer } from './styled';

interface SnackBarProps {
  alerts: AlertProps[];
  autoHideDuration?: number;
}

export const SnackBar: React.FC<SnackBarProps> = ({ alerts, autoHideDuration = 3000 }) => {
  const [queue, setQueue] = useState<AlertProps[]>([]);

  useEffect(() => {
    setQueue((prevQueue) => [...prevQueue, ...alerts]);
  }, [alerts]);

  useEffect(() => {
    if (queue.length > 0) {
      const timerId = setTimeout(() => {
        setQueue((prevQueue) => prevQueue.slice(1));
      }, autoHideDuration);

      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {};
  }, [queue, autoHideDuration]);

  const handleClose = () => {
    setQueue((prevQueue) => prevQueue.slice(1));
  };

  return (
    <StyledSnackBarContainer>
      {queue.map((alert) => (
        <Alert key={alert.text} onClose={handleClose} {...alert} opened />
      ))}
    </StyledSnackBarContainer>
  );
};

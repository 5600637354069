import { FC, useCallback, useMemo } from 'react';
import { Image, Typography } from '@pixy/ui';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../shared';
import {
  GridContainer,
  LargeItem,
  LargeItemInverted,
  SmallItem,
  StyledDescriptionWrapper,
} from './styled';
import { useTelegram } from '../../../telegram';
import { useSendStyle } from '../../../styles/hooks';

const IMAGES_AMOUNT = 187;
const images = new Array(IMAGES_AMOUNT).fill(0);

const getImageUrl = (id: number) => {
  return `https://pixy.fra1.cdn.digitaloceanspaces.com/public/twa/swap/${id}.jpg`;
};

export const SwapScreen: FC = () => {
  const { t } = useTranslation();
  const { queryId } = useTelegram();
  const { request } = useSendStyle();

  const onClickHandler = useCallback(
    (url: string) => {
      if (queryId) {
        request({ queryId, imageUrl: url });
      }
    },
    [queryId, request],
  );

  const imagesMasonry = useMemo(() => {
    return (
      <GridContainer>
        {images.map((_, index) => {
          const position = index % 18;
          const imageURL = getImageUrl(index + 1);

          if (position === 0) {
            return (
              <LargeItem
                key={imageURL}
                onClick={() => onClickHandler(imageURL)}
              >
                <Image src={imageURL} />
              </LargeItem>
            );
          }

          if (position < 5) {
            return (
              <SmallItem
                key={imageURL}
                onClick={() => onClickHandler(imageURL)}
              >
                <Image src={imageURL} />
              </SmallItem>
            );
          }

          if (position === 11) {
            return (
              <LargeItemInverted
                key={imageURL}
                onClick={() => onClickHandler(imageURL)}
              >
                <Image src={imageURL} />
              </LargeItemInverted>
            );
          }

          if (position > 11 && position < 14) {
            return (
              <SmallItem
                key={imageURL}
                onClick={() => onClickHandler(imageURL)}
              >
                <Image src={imageURL} />
              </SmallItem>
            );
          }

          return (
            <SmallItem
              key={imageURL}
              onClick={() => onClickHandler(imageURL)}
            >
              <Image src={imageURL} />
            </SmallItem>
          );
        })}
      </GridContainer>
    );
  }, [onClickHandler]);

  return (
    <PageContainer noPadding>
      <StyledDescriptionWrapper>
        <Typography variant="headerXS">{t('swap.pageHeader')}</Typography>
        <Typography color="neutral10" variant="textSMBold">
          {t('swap.pageDescription')}
        </Typography>
      </StyledDescriptionWrapper>
      {imagesMasonry}
    </PageContainer>
  );
};

import { Typography } from '@pixy/ui';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledBoardContainer = styled.div`
  width: 100%;
`;

export const BaseContainer = styled.div`
  width: 100%;
  ${({ theme }) => css`
    border-radius: ${theme.spacing.get(4)};
    padding: ${theme.spacing.get(3, 4)};
    background: ${theme.palette.neutral80};

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing.get(4, 6)};
    }
  `}
`;

export const StyledDescriptionBlock = styled(BaseContainer)`
  position: relative;
  overflow: hidden;

  ${({ theme }) => css`
    background: linear-gradient(
      270deg,
      ${theme.palette.neutral60} 0%,
      ${theme.palette.neutral80} 66.93%
    );

    max-width: calc(50% - ${theme.spacing.get(2)});

    ${theme.breakpoints.up('md')} {
      min-height: ${theme.spacing.get(50)};
    }
  `}
`;

export const StyledAmountBlock = styled(BaseContainer)`
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      min-height: ${theme.spacing.get(38)};
    }
  `}
`;

export const StyledActionBlock = styled(BaseContainer)``;

export const LinkContainer = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

export const IconHolder = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral20};
`;

export const ImageContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 80%;
  z-index: 0;
  
  ${({ theme }) => css`
  ${theme.breakpoints.up('md')} {
      width: ${theme.spacing.get(40)};
      height: 100%;
      top: 0;
      bottom: unset;
      right: 0;
      width: ${theme.spacing.get(50)};
    }
  `}
`;

export const StyledSubtitle = styled(Typography)`
    ${({ theme }) => css`
      ${theme.typography.captureSM};
      ${theme.palette.neutral10};

      ${theme.breakpoints.up('md')} {
        ${theme.typography.textXS}
      }
    `}
`;

export const StyledPlanTitle = styled(Typography)`
      ${({ theme }) => css`
        ${theme.typography.textSMBold};

        ${theme.breakpoints.up('md')} {
          ${theme.typography.textLGBold};
        }
      `}
`;

export const StyledAmountTitle = styled(Typography)`
     ${({ theme }) => css`
        ${theme.typography.headerMD}

        ${theme.breakpoints.up('md')} {
          ${theme.typography.headerLG};
        }
     `}
`;

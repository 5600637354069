import styled, { css } from 'styled-components';

export interface StyledSelectProps {
  opened: boolean;
}

export const SelectButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    ${theme.typography.textXS};
    padding: ${theme.spacing.get(2)};
    border: 1px solid ${theme.palette.neutral60};
    border-radius: ${theme.spacing.get(2)};
    gap: ${theme.spacing.get(0.5)};
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.spacing.get(4)};
    display: flex;
    align-items: center;
  `}
`;

export const ButtonIconContainer = styled(IconContainer)`
  flex-shrink: 0;
  transition: transform 0.3s ease-out;
`;

export const CheckedIconContainer = styled(IconContainer)`
  ${({ theme }) => css`
    color: ${theme.palette.primary100};
    margin-left: ${theme.spacing.get(1)};
    font-size: ${theme.spacing.get(5)};
  `}
`;

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  z-index: 9998;
  display: none;
  ${({ theme }) => css`
    background: ${theme.palette.background.overlay};
  `}
`;

export const OptionsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: fit-content;
  z-index: 9999;
  ${({ theme }) => css`
    top: ${theme.spacing.get(10)};
    padding: ${theme.spacing.get(2, 6)};
    background: ${theme.palette.neutral100};
    border-radius: ${theme.spacing.get(6)};
    box-shadow: 0 0 ${theme.spacing.get(2.5)} 0 ${theme.palette.neutral80};

    ${theme.breakpoints.down('md')} {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      top: unset;
      padding: ${theme.spacing.get(4, 6, 6)};
      border-radius: ${theme.spacing.get(6, 6, 0, 0)};
      box-shadow: unset;
    }
  `}
`;

export const StyledSelect = styled.div<StyledSelectProps>`
  position: relative;

  ${({ opened }) => opened && css`
    ${ButtonIconContainer} {
      transform: rotate(-180deg);
    } 
    
    ${StyledOverlay}{
      opacity: 0.6;
      display: block;
    }
  `
}
`;

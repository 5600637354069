import styled from 'styled-components';

export const StyledInput = styled.input`
  display: block;
  min-width: 0px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.get(2, 3)};
  border: none;
  font: inherit;
  color: ${({ theme }) => theme.palette.neutral5};
  background: transparent;
  outline: none;

  textarea& {
    min-height: 164px;
    resize: none;
  }

  &:first-child {
    padding-left: ${({ theme }) => theme.spacing.get(4)};
  }

  &:last-child {
    padding-right: ${({ theme }) => theme.spacing.get(4)};
  }
`;

import { useTranslation } from 'react-i18next';

export const useTimeFormatter = () => {
  const { t } = useTranslation();

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const formattedDays = days > 0 ? t('pluralize.day', { count: days }) : '';
    const formattedHours = hours % 24 > 0 ? t('pluralize.hour', { count: hours % 24 }) : '';
    const formattedMinutes = minutes % 60 > 0 ? t('pluralize.minute', { count: minutes % 60 }) : '';

    const timeArray = [formattedDays, formattedHours, formattedMinutes];
    const formattedTime = timeArray.filter((time) => time !== '').join(' ');

    return formattedTime !== '' ? formattedTime : t('pluralize.minute', { count: 0 });
  };

  return { formatTime };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import QueryString from 'qs';
import { Listener } from '../common';
import { PreparedRequestOptions, RequestOptions, RequestResponse } from './types';
import { request } from './request';
import { RequestError } from './RequestError';

interface HttpClientEventMap {
  error: {
    error: RequestError,
    options: RequestOptions,
  };
}

export type HttpClientAuthProvider = (
  options: RequestOptions
) => RequestOptions | Promise<RequestOptions>;

export class HttpClient extends Listener<HttpClientEventMap> {
  private _defaultOptions: Partial<RequestOptions> = {};

  private _authProviders: Array<HttpClientAuthProvider> = [];

  get defaultOptions() {
    return this._defaultOptions;
  }

  set defaultOptions(options: Partial<RequestOptions>) {
    this._defaultOptions = {
      ...this._defaultOptions,
      ...options,
    };
  }

  addAuthProvider = (provider: HttpClientAuthProvider) => {
    this._authProviders.push(provider);
  };

  removeAuthProvider = (provider: HttpClientAuthProvider) => {
    this._authProviders = this._authProviders.filter((x) => x !== provider);
  };

  request = async <T = any>(options: RequestOptions): Promise<RequestResponse<T>> => {
    const preparedOptions = await this.prepareRequestOptions(options);
    try {
      return await request<T>(preparedOptions);
    } catch (error) {
      this.trigger('error', {
        error,
        options,
      });
      throw error;
    }
  };

  prepareRequestOptions = async (
    options: RequestOptions,
  ): Promise<PreparedRequestOptions> => {
    let updatedOptions = {
      ...this.defaultOptions,
      ...options,
    };
    if (updatedOptions.withAuth && this._authProviders.length > 0) {
      for (let i = 0; i < this._authProviders.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        updatedOptions = await this._authProviders[i](updatedOptions);
      }
    }

    let {
      baseUrl = '',
      method = 'GET',
      headers = {},
      body,
      contentType = 'json',
      params,
      path,
    } = updatedOptions;

    let requestBody: string | FormData | null = null;
    if (body && contentType === 'json') {
      requestBody = JSON.stringify(body);
      headers = {
        ...headers,
        'Content-Type': 'application/json',
      };
    } else if (body && contentType === 'formData') {
      if (body instanceof FormData) {
        requestBody = body;
      } else {
        requestBody = new FormData();
        Object.keys(body).forEach((key) => {
          (requestBody as FormData).append(key, (body as any)[key]);
        });
      }
    }

    let url = path;
    if (baseUrl) {
      url = baseUrl + url;
    }
    if (params) {
      url = url + (url.includes('?') ? '' : '?') + QueryString.stringify(params);
    }

    return {
      url,
      method,
      headers,
      body: requestBody,
    };
  };
}

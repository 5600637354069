import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  AlertProps,
  ArrowRightIcon,
  InfoIcon,
  SnackBar,
  TextInput,
  Typography,
  useBreakpoint,
} from '@pixy/ui';
import { useTranslation } from 'react-i18next';
import { useApplyPromotion } from '@pixy/core';
import { StyledPromocodesContainer, StyledTitleContainer } from './styled';
import { useTimeFormatter } from '../../shared';

export const PromoCodesBoard: FC = () => {
  const { t, i18n } = useTranslation();
  const { formatTime } = useTimeFormatter();
  const { result, error, request: promoRequest } = useApplyPromotion();
  const isMobile = useBreakpoint('down', 'md');
  const [promoCode, setPromoCode] = useState('');
  const [errorStatus, setErrorStatus] = useState<string | boolean>(false);
  const [successStatus, setSuccessStatus] = useState<string | boolean>(false);
  const [promoDetails, setPromoDetails] = useState<string | null>(null);
  const locale = i18n.language;

  const handleActionClick = useCallback(() => {
    if (promoCode.length) {
      promoRequest({ code: promoCode });
    }
  }, [promoCode, promoRequest]);

  const handleInputChange = useCallback(
    (value: string) => {
      if (errorStatus || successStatus) {
        setErrorStatus(false);
        setSuccessStatus(false);
      }
      setPromoCode(value);
    },
    [errorStatus, successStatus],
  );

  const alertsData: AlertProps[] = useMemo(() => {
    if (promoDetails) {
      return [
        {
          text: promoDetails,
          variant: 'black',
          closable: true,
          icon: <InfoIcon fontSize={16} />,
        },
      ];
    }

    return [];
  }, [promoDetails]);

  useEffect(() => {
    if (result) {
      setSuccessStatus(t('wallet.promotions.success') as string);

      const {
        amount,
        executionNumber: planAmount,
        executionPeriod: planPeriod,
      } = result;

      const { isPackage } = result;

      let message = `${t('wallet.promotions.terms.you_got')} ${t('pluralize.generation', { count: amount })}!`;

      if (!isPackage) {
        message = `${t('wallet.promotions.terms.you_got')} ${t('pluralize.generation', { count: planAmount })} ${t('wallet.manage.terms.per')} ${formatTime(planPeriod)}!`;
      }

      setPromoDetails(message);
    }

    if (error) {
      setErrorStatus(t('wallet.promotions.error') as string);
    }
  }, [error, locale, result, t]);

  return (
    <StyledPromocodesContainer>
      <StyledTitleContainer>
        <Typography variant={isMobile ? 'textSMBold' : 'textMDBold'}>
          {t('wallet.promotions.title')}
        </Typography>
      </StyledTitleContainer>
      <TextInput
        allowAction
        fullWidth
        value={promoCode}
        placeholder={t('wallet.promotions.placeholder') as string}
        error={errorStatus}
        success={successStatus}
        actionIcon={<ArrowRightIcon fontSize={16} />}
        onChangeText={handleInputChange}
        onAction={handleActionClick}
      />
      <SnackBar autoHideDuration={5000} alerts={alertsData} />
    </StyledPromocodesContainer>
  );
};

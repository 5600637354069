import { apiClient } from '../../api';
import { defaultPlan } from '../data';
import { Balance } from '../models';

export const loadBalance = async (): Promise<Balance> => {
  const response = await apiClient.request({
    path: '/api/v1/balance',
    method: 'GET',
    withAuth: true,
  });

  if (response.data.current_offer === null) {
    response.data.current_offer = defaultPlan;
  }

  return Balance.fromDTO(response.data);
};

import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  CardIcon,
  CaretRightIcon,
  CryptowalletIcon,
  Divider,
  Image,
  Modal,
  Stack,
  Typography,
  useBreakpoint,
} from '@pixy/ui';
import { PaymentMethod, useGeneratePaymentLink, usePrice } from '@pixy/core';
import {
  IconWrapper,
  PaymentOptionIconContainer,
  StyledPaymentCard,
  TypeIconContainer,
} from './styled';

import { PaymentModal } from './elements/PaymentModal';
import { CurrencyIcon } from '../../shared';

export interface PaymentCardProps {
  item: PaymentMethod;
  isDisabled?: boolean;
  onClick?: ({ id, email }) => void;
}

export const PaymentCard: FC<PaymentCardProps> = ({
  item,
  isDisabled = false,
  onClick,
}) => {
  const {
    id, name, image, currency, price, type,
  } = item;

  const { isLoading } = useGeneratePaymentLink();
  const isMobile = useBreakpoint('down', 'md');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLocked, setIsLocked] = useState(isDisabled);
  const isMailModalExists = id === 'yookassa';
  const isFiat = type === 'fiat';

  const formattedPrice = usePrice(price, currency);

  const getFormattedPrice = useMemo(() => {
    if (isFiat) {
      return (
        <>
          {formattedPrice.currency.symbolPlacement === 'start' && (
            <IconWrapper><CurrencyIcon code={currency} /></IconWrapper>
          )}
          <Typography variant="textMDBold" color="neutral5">
            {formattedPrice.value}
          </Typography>
          {formattedPrice.currency.symbolPlacement === 'end' && (
            <IconWrapper><CurrencyIcon code={currency} /></IconWrapper>
          )}
        </>
      );
    }
    return (
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="textMDBold" color="neutral5">
          {formattedPrice.formattedValue}
        </Typography>
        <Typography variant="textLG" color="neutral10">
          {formattedPrice.currency.symbol}
        </Typography>
      </Stack>
    );
  }, [
    currency,
    formattedPrice.currency.symbol,
    formattedPrice.currency.symbolPlacement,
    formattedPrice.formattedValue,
    formattedPrice.value,
    isFiat,
  ]);

  const onPaymentSubmitHandler = useCallback(
    ({ email = '' }) => {
      if (onClick) {
        onClick({ id, email });
      }
      setIsLocked(true);
      setIsModalOpened(false);
    },
    [id, onClick],
  );

  const onClickHandler = useCallback(
    () => {
      if (isLocked) {
        return false;
      }

      if (!isMailModalExists) {
        return onPaymentSubmitHandler({});
      }

      setIsModalOpened(true);

      return true;
    },
    [isLocked, isMailModalExists, onPaymentSubmitHandler],
  );

  const onCloseModalHandler = useCallback(() => {
    setIsModalOpened(false);
  }, []);

  useEffect(() => {
    setIsLocked(isDisabled);
  }, [isDisabled, isLoading, name]);

  return (
    <>
      <StyledPaymentCard
        onClick={onClickHandler}
        disabled={isLocked || isLoading}
      >
        <Stack direction="row" alignItems="center">
          <TypeIconContainer>
            {isFiat ? (
              <CardIcon fontSize={24} />
            ) : (
              <CryptowalletIcon fontSize={24} />
            )}
          </TypeIconContainer>

          <Stack
            style={{ flex: '1' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="textSMBold" color="neutral5">
              {name}
            </Typography>
            <CaretRightIcon width={12} height={12} />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" alignItems="center">
          <Stack style={{ flex: '1' }} direction="row" alignItems="center">
            {getFormattedPrice}
          </Stack>
          {image && (
            <PaymentOptionIconContainer>
              <Image src={image ?? ''} />
            </PaymentOptionIconContainer>
          )}
        </Stack>
      </StyledPaymentCard>

      {isMailModalExists && (
        <Modal
          topOffset={isMobile ? 80 : 0}
          width={335}
          opened={isModalOpened}
          onClose={onCloseModalHandler}
        >
          <PaymentModal onSubmit={onPaymentSubmitHandler} />
        </Modal>
      )}
    </>
  );
};

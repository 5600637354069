import styled, { css } from 'styled-components';

export const StyledTagCloudContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    ${({ theme }) => css`
        gap: ${theme.spacing.get(3)};
        margin-top: ${theme.spacing.get(4)};
    `}
`;

export const StyledCreateStylesButtonContainer = styled.div`
    display: flex;
    align-items: center;
    ${({ theme }) => css`
        gap: ${theme.spacing.get(3)};
        margin-top: ${theme.spacing.get(5)};
        margin-bottom: ${theme.spacing.get(5)};
    `}
`;

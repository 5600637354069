import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, TabLink } from '@pixy/ui';
import { navigationTabs } from '../misc/const';
import { config } from '../../../config';

export const AppTabs: FC = () => {
  const location = useLocation();

  const tabs = useMemo(() => {
    if (config.components) {
      return navigationTabs.filter((item) => config.components?.includes(item.id));
    }

    return navigationTabs;
  }, []);

  if (tabs.length < 2) {
    return null;
  }

  const activeIndex = navigationTabs.findIndex(
    (route) => route.path === location.pathname,
  );

  const tabComponents = tabs.map(({ path, icon }) => {
    return (
      <TabLink key={path} path={path}>
        {icon}
      </TabLink>
    );
  });

  return <Tabs tabs={tabComponents} activeIndex={activeIndex} />;
};

import * as React from 'react';
import type { SVGProps } from 'react';

const SvgTextAlignLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M3 7a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1ZM4 11a1 1 0 1 0 0 2h9a1 1 0 0 0 0-2H4ZM3 17a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z"
    />
  </svg>
);
export default SvgTextAlignLeft;

import React from 'react';
import { CheckCircleIcon, ClockIcon, CloseCircleIcon } from '@pixy/ui';
import { OrderStatus } from '@pixy/core';
import { StatusIconWrapper } from './styled';

enum PaymentStatus {
  Pending = 'pending',
  Success = 'success',
  Error = 'error'
}

type PaymentStatusMapping = Record<OrderStatus, PaymentStatus>;

const paymentStatusMapping: PaymentStatusMapping = {
  [OrderStatus.New]: PaymentStatus.Pending,
  [OrderStatus.Pending]: PaymentStatus.Pending,
  [OrderStatus.Success]: PaymentStatus.Success,
  [OrderStatus.Error]: PaymentStatus.Error,
  [OrderStatus.Cancelled]: PaymentStatus.Error,
};

export const getIconByStatus = (status: OrderStatus) => {
  const statusValue = paymentStatusMapping[status];

  switch (statusValue) {
    case PaymentStatus.Pending:
      return (
        <StatusIconWrapper color="neutral20">
          <ClockIcon width={20} height={20} />
        </StatusIconWrapper>
      );
    case PaymentStatus.Success:
      return (
        <StatusIconWrapper color="success100">
          <CheckCircleIcon width={20} height={20} />
        </StatusIconWrapper>
      );
    case PaymentStatus.Error:
      return (
        <StatusIconWrapper color="danger100">
          <CloseCircleIcon width={20} height={20} />
        </StatusIconWrapper>
      );
    default:
      return null;
  }
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, ReactNode } from 'react';
import {
  CaretLeftIcon,
  IconButton,
  Typography,
} from '@pixy/ui';
import { Link } from 'react-router-dom';
import {
  StyledContainer,
  StyledContainerProps,
  StyledHeaderContainer,
  StyledTitle,
} from './styled';

interface PageContainerProps extends StyledContainerProps {
  children: ReactNode;
  backUrl?: string;
  noPadding?: boolean;
  toolbar?: ReactNode;
}

export const PageContainer: FC<PageContainerProps> = ({
  children,
  title = '',
  backUrl = null,
  noPadding = false,
  toolbar,
}) => {
  return (
    <StyledContainer title={title} noPadding={noPadding}>
      {title && (
      <StyledHeaderContainer>
        <StyledTitle>
          {backUrl && (
          <IconButton
                // @ts-ignore
            as={Link}
            to={backUrl}
            color="transparent"
          >
            <CaretLeftIcon width="24px" height="24px" />
          </IconButton>
          )}
          <Typography variant="headerXS">{title}</Typography>
        </StyledTitle>
        {toolbar}
      </StyledHeaderContainer>
      )}
      {children}
    </StyledContainer>
  );
};

import React, {
  FC, useState, useEffect, useRef, useCallback,
} from 'react';
import {
  OptionsContainer,
  SelectButton,
  StyledSelect,
  Option,
  CheckedIconContainer,
  ButtonIconContainer,
  StyledOverlay,
} from './styled';
import { CheckIcon, SmallCaretDownIcon } from '../Icons';
import { useBreakpoint } from '../../hooks';

export interface OptionType {
  value: string;
  label: React.ReactNode;
}

export interface SelectProps {
  options: OptionType[];
  defaultValue?: string;
  onValueChange?: (value: string) => void;
}

const OFFSET = 8;

export const Select: FC<SelectProps> = ({
  options,
  defaultValue = '',
  onValueChange,
}) => {
  const isMobile = useBreakpoint('down', 'md');

  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [optionsStyles, setOptionsStyles] = useState({});

  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const optionsContainerRef = useRef<HTMLDivElement | null>(null);

  const calculateDropdownStyles = useCallback(() => {
    if (!buttonRef.current || !optionsContainerRef.current) return;
    let newStyles = {};
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const dropdownRect = optionsContainerRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    const overflowRight = buttonRect.left + dropdownRect.width > viewportWidth;
    const overflowBottom = buttonRect.bottom + dropdownRect.height > viewportHeight;

    if (overflowRight) {
      newStyles = { ...newStyles, right: 0 };
    }
    if (overflowBottom) {
      newStyles = { ...newStyles, top: -dropdownRect.height - OFFSET };
    }

    setOptionsStyles(newStyles);
  }, [buttonRef, optionsContainerRef]);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);

    if (!isMobile) {
      setOptionsStyles({});
    }
  }, []);

  const handleChange = useCallback(
    (value: string) => {
      setSelectedValue(value);
      closeDropdown();

      if (onValueChange) {
        onValueChange(value);
      }
    },
    [setIsOpen, setSelectedValue, setOptionsStyles, onValueChange],
  );

  const handleClickOutside = useCallback(
    (event: Event) => {
      if (
        containerRef.current
        && !containerRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    },
    [setIsOpen, setOptionsStyles],
  );

  const handleClick = useCallback(() => {
    setIsOpen((prevValue) => !prevValue);
  }, [setIsOpen]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && !isMobile) {
      setTimeout(calculateDropdownStyles, 0);
    }
  }, [isOpen]);

  return (
    <StyledSelect ref={containerRef} opened={isOpen}>
      {isMobile && (
        <StyledOverlay onClick={() => closeDropdown()} />
      )}
      <SelectButton ref={buttonRef} onClick={handleClick}>
        {selectedValue}
        <ButtonIconContainer>
          <SmallCaretDownIcon />
        </ButtonIconContainer>
      </SelectButton>
      {isOpen && (
        <OptionsContainer ref={optionsContainerRef} style={optionsStyles}>
          {options.map((option) => (
            <Option
              key={option.value}
              onClick={() => handleChange(option.value)}
            >
              {option.label}
              {option.value === selectedValue ? (
                <CheckedIconContainer>
                  <CheckIcon />
                </CheckedIconContainer>
              ) : null}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </StyledSelect>
  );
};

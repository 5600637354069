import styled, { css } from 'styled-components';

export interface StyledTitleWrapperProps {
  withOffset?: boolean;
}

export const StyledTitleWrapper = styled.div<StyledTitleWrapperProps>`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${({ theme, withOffset }) => {
    let padding = theme.spacing.get(4, 0, 0);

    if (withOffset) {
      padding = theme.spacing.get(4, 5, 0);
    }

    return css`
      padding: ${padding}
    `;
  }}`;

import styled, { css } from 'styled-components';
import { ThemePaletteKey } from '../../themes';

export type DividerProps = {
    color?: ThemePaletteKey
}

export const Divider = styled.div<DividerProps>`
    width: 100%;
    height: 1px;
    ${({ theme, color }) => css`   
        background: ${color ? theme.palette[color] : theme.palette.neutral60};
        margin: ${theme.spacing.get(4, 0)};
        border-radius: ${theme.spacing.get(3)};
    `}
`;

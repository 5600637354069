import React, { FC, ReactNode, useMemo } from 'react';
import { WalletContext, WalletContextValue } from './WalletContext';
import { useLoadBalance } from '../../hooks';

export interface WalletProviderProps {
  children: ReactNode;
}

export const WalletContainer: FC<WalletProviderProps> = (props) => {
  const { children } = props;
  const {
    result: balance,
    isLoading: balanceIsLoading,
    error: balanceError,
    request: refreshBalance,
  } = useLoadBalance();

  const contextValue: WalletContextValue = useMemo(() => {
    return {
      balance,
      balanceIsLoading,
      balanceError,
      refreshBalance,
    };
  }, [
    balance,
    balanceIsLoading,
    balanceError,
    refreshBalance,
  ]);

  return (
    <WalletContext.Provider value={contextValue}>{children}</WalletContext.Provider>
  );
};

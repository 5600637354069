import styled, { css, keyframes } from 'styled-components';

export interface SkeletonProps {
  height?: string;
}

export const skeletonAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const StyledSkeleton = styled.div<SkeletonProps>`
  height: ${({ height }) => height ?? '100%'};
  width: 100%;
  border-radius: 12px;
  ${({ theme }) => css`
    background: linear-gradient(90deg, ${theme.palette.neutral40}, ${theme.palette.neutral60}, ${theme.palette.neutral40});
  `}
  background-size: 200% 200%;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
`;

import styled, { css } from 'styled-components';
import { ThemeTypographyKey, ThemePaletteKey } from '../../themes';

export interface TypographyProps {
  variant?: ThemeTypographyKey;
  color?: ThemePaletteKey | string |'inherit';
}

export const Typography = styled.span<TypographyProps>`
  ${({ theme, variant = 'base', color = 'inherit' }) => css`
    ${theme.typography[variant]}
    color: ${color in theme.palette ? theme.palette[color] : color};
  `}
`;
